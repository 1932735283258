
import {  gql } from 'graphql-request'

export const ProfilePic = gql`
 mutation singleUpload($file: Upload! ) {
  singleUpload(file: $file) {
    filename
      mimetype
      encoding
  }
}
`;

export const login = gql`
 mutation signIn($login: String!, $password: String!) {
  signIn(login: $login, password: $password) {
   role
   username
  }
}
`;
export const Signup = gql`
 mutation signUp($name: String!, $email: String!, $password: String!,$role: String!) {
  signUp(name: $name, email: $email, password: $password, role: $role) {
   role
   username
  }
}
`;
export const UserUpdate = gql`
 mutation userUpdate($name: String, $phone: String, $city: String,$state: String,$pic: String,$title: String,$language: String, $age: String, $salary: String,$expected: String,$description: String,$country: String,$postalcode: String,$address: String) {
  userUpdate(name: $name, phone: $phone, city: $city, state: $state, pic: $pic, title: $title, language: $language, age: $age, salary: $salary, expected: $expected,description: $description, country: $country, postalcode: $postalcode, address: $address ) {
   response
  }
}
`;
export const ContactUs = gql`
 mutation contactUs($name: String!, $email: String!, $message: String!) {
  contactUs(name: $name, email: $email, message: $message) {
    response
  }
}
`;
export const AddSkill = gql`
 mutation addSkill($skill: String!) {
  addSkill(skill: $skill) {
    id
    skills
    createdAt
  }
}
`;
export const RATE_USER = gql`
 mutation sendReview($rating: String!, $review: String!, $user: String!) {
  sendReview(rating: $rating, review: $review, user: $user) {
    id
    review
    rating
    createdAt
  }
}
`;
export const SAVE_PAYMENT = gql`
 mutation savePayment($channel: String!, $amount: Int!,$txnId: String!) {
  savePayment(channel: $channel, amount: $amount, txnId: $txnId) {
    id
    channel
    amount
    status
    createdAt
  }
}
`;
export const ChangePassword = gql`
 mutation changePassword($password: String!, $confirm: String!) {
  changePassword(password: $password, confirm: $confirm) {
    response
  }
}
`;

export const VerifyEmail = gql`
 mutation VerifyEmail($token: String!) {
  VerifyEmail(token: $token) {
    response
  }
}
`;
export const SEND_TOKEN = gql`
query{
  send_token{
    response
  }
}
`;
export const CreateExprience = gql`
 mutation createExprience($designation: String!,$organization: String!,$owner: String!,$sdate: Date!,$edate: Date!,$des: String!) {
  createExprience(designation: $designation, organization: $organization,owner: $owner, sdate: $sdate, edate: $edate, des: $des){
    id
    designation
    organization
    owner
    sdate
    edate
    des
    createdAt
  }
}
`;
export const EditExprience = gql`
 mutation editExprience($id: String!,$designation: String!,$organization: String!,$owner: String!,$syear: String!,$smonth: String!,$eyear: String!,$emonth: String!,$des: String!) {
  editExprience(id: $id,designation: $designation, organization: $organization,owner: $owner, syear: $syear,smonth: $smonth, eyear: $eyear,emonth: $emonth, des: $des) {
    response
  }
}
`;
export const DeleteExprience = gql`
 mutation deleteExprience($id: String!) {
  deleteExprience(id: $id) {
    response
  }
}
`;
export const OneExprience = gql`
query exprience($id: String!){
  exprience(id: $id){
    edges{
      id
      designation
      organization
      owner
      sdate
      edate
      des
      createdAt
    }
  }
}
`;
export const CreateProject = gql`
 mutation createProject($title: String!,$client: String!,$status: String!,$sdate: Date!,$edate: Date!,$des: String!) {
  createProject(title: $title, client: $client,status: $status, sdate: $sdate, edate: $edate, des: $des) {
    id
    title
    client
    status
    sdate
    edate
    des
    createdAt
  }
}
`;
export const EditProject = gql`
 mutation editProject($id: String!,$title: String!,$client: String!,$status: String!,$syear: String!,$smonth: String!,$eyear: String!,$emonth: String!,$des: String!) {
  editProject(id: $id,title: $title, client: $client,status: $status,syear: $syear,smonth: $smonth, eyear: $eyear,emonth: $emonth, des: $des) {
    response
  }
}
`;
export const DeleteProject = gql`
 mutation deleteProject($id: String!) {
  deleteProject(id: $id) {
    response
  }
}
`;
export const OneProject = gql`
query project($id: String!){
  project(id: $id){
    edges{
      id
      title
      client
      status
      sdate
      edate
      des
      createdAt
    }
  }
}
`;
export const userDetail = gql`
query{
  user{
    id
    isverified
    username
    name
    email
    role
    phone
    pic
    title
    language
    age
    salary
    expected
    description
    country
    postalcode
    city
    state
    address
    payment_status
    messages{
      id
      text
    }
    skills{
      id
      skills
      createdAt
    }
    projects{
      id
      title
      client
      status
      sdate
      edate
      des
      createdAt
    }
    expriences{
      id
      designation
      organization
      owner
      sdate
      edate
      des
      createdAt
    }
    uploads{
      id
      url
      createdAt
    }
    proves{
      id
      url
      createdAt
    }
    unread_chats{
      id
      message_id
      sender_id
      sender
      reciever_id
      reciever_name
      type
      message
      status
    }
  }
}
`;
export const userpic = gql`
query{
  user{
    pic
  }
}
`;
export const getuser = gql`
query getUser($id: ID!){
  getUser(id: $id){
    id
    username
    name
    email
    role
    phone
    pic
    title
    language
    age
    salary
    expected
    description
    country
    postalcode
    city
    state
    address
    online
    createdAt
    messages{
      id
      text
    }
    skills{
      id
      skills
      createdAt
    }
    projects{
      id
      title
      client
      status
      sdate
      edate
      des
      createdAt
    }
    expriences{
      id
      designation
      owner
      sdate
      edate
      des
      createdAt
    }
    uploads{
      id
      url
      createdAt
    }
    reviews{
      id
      review
      rating
      createdAt
    }   
  }
}
`;
export const chats = gql`
query chat($user: String!){
  chat(user: $user){
    id
    message_id
    sender_id
    sender
    reciever_id
    reciever_name
    type
    message
    status
    createdAt
    file{
      id
      url
    }
    unread{
      id
      message_id
      sender_id
      sender
      reciever_id
      reciever_name
      type
      message
      status
    }
    }
  }
`;
export const thread = gql`
query chats{
  chats{
    id
    message_id
    sender_id
    sender
    reciever_id
    reciever_name
    type
    message
    status
    createdAt
    unread{
      message_id
      sender_id
      sender
      reciever_id
      reciever_name
      type
      message
      status
      createdAt
    }
    senderDetails{
      id
    username
    name
    email
    role
    phone
    pic
    title
    language
    age
    salary
    expected
    description
    country
    postalcode
    city
    state
    address
    online
    createdAt
    }
    lastMsg{
      sender_id
      sender
      reciever_id
      reciever_name
      type
      message
      status
      createdAt
    }
    }
  }
`;
export const REFRESH = gql`
query{
  refresh_token{
    minutes
    role
    username
  }
}
`;
export const LOGOUT = gql`
mutation{
  signOut
}
`;
export const RecoverPassword = gql`
 mutation passwordRecovery($email: String!) {
  passwordRecovery(email: $email)
}
`;
export const checkToken = gql`
 mutation checkToken($token: String!) {
  checkToken(token: $token)
}
`;
export const resetUserPassword = gql`
mutation resetUserPassword($password: String!, $token: String!) {
  resetUserPassword(token: $token, password: $password)
}`
export const RESET_PASSWORD = gql`
mutation reset_user_password($password: String!, $token: String!,$email: Srting!) {
  reset_password(object: {
      email:$email
      password: $password,
      token: $token
  })
}`
export const MESSAGES = gql`
query {
  messages {
    edges {
      id
      text
      sender
      reciever
      schedule
      createdAt
    }
    pageInfo {
      endCursor
    }
  }
}
`

export const USERS = gql`
query{
  users{
    id
    name
    email
    role
    phone
    pic
    title
    language
    age
    salary
    expected
    description
    country
    postalcode
    city
    state
    address
    skills{
      id
      skills
    }
    uploads{
      id
      url
      createdAt
    }
    projects{
      id
      title
      client
      status
      sdate
      edate
      des
      createdAt
    }
    expriences{
      id
      designation
      organization
      owner
      sdate
      edate
      des
      createdAt
    }
    reviews{
      id
      review
      rating
      createdAt
    }
    createdAt
  }
}
`
