import React,{useEffect,useState}  from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import PageTitle from './../Layout/PageTitle';
import Jobfindbox from './../Element/Jobfindbox';
import {useSelector,useDispatch} from 'react-redux';
import {graphQLClient}  from '../../client';
import {USERS} from '../../queries';
import {LOAD_USERS,FILTER_USERS} from '../../store/actions';
import Loader from '../Element/Loader'
import Handimans from '../Element/handimans';

//Images

var bnr = require('./../../images/background/pexels-cottonbro-5089178.jpg');


function Browsejobfilterlist(props){
	// console.log(props.location.state)
	
	const [loading, setLoading] = useState(true)
	const users = useSelector((state)=> state.web.users)
	const dispatch = useDispatch()
	const func = async() => {
		try {
			
				const data = await graphQLClient.request(USERS)
				console.log(data.users)
			dispatch(LOAD_USERS(data.users));	
			setLoading(false)
			if(props.location.state && data){
				dispatch(FILTER_USERS(props.location.state.search));
			}
	
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 
// eslint-disable-next-line
	useEffect(()=>{
	func()
	},[])
	return(
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + bnr + ")" }}>
					<PageTitle motherName="Home" activeName="Handimen" />
				</div>
				<Jobfindbox />
				<div className="content-block">
					<div className="section-full browse-job p-b50">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
						<div className="sticky-top">
						<div className="candidates-are-sys m-b30">
								<div className="candidates-bx">
									<div className="testimonial-pic radius"><img src={require("./../../images/testimonials/alex-starnes-WYE2UhXsU1Y-unsplash.jpg")} alt=""  style={{width: '100%', height:'100%'}}/></div>
									<div className="testimonial-text">
										<p>I get my regular job request via handiman executive! I used the site all the time.</p>
									</div>
									<div className="testimonial-detail"> <strong className="testimonial-name">Constants Ikechukwu</strong> <span className="testimonial-position">Port Harcourt, Rivers</span> </div>
								</div>
							</div>
							<div className="quote-bx">
								<div className="quote-info">
									<h4>Expose your skills to the world around you!</h4>
									<p>The world should know what you can do.</p>
									<Link to={"/register"} className="site-button">Create an Account</Link>
								</div>
							</div>
						</div>
					</div>
								<div className="col-xl-9 col-lg-8 col-md-7">
									<div className="job-bx-title clearfix">
										<h5 className="font-weight-700 pull-left text-uppercase">
											{
												loading?
												null
												:
												`${users?.length} Handimans Found`
											}
											
											</h5>
										<div className="float-right">
											<span className="select-title">Sort by freshness</span>
											<select className="custom-btn">
												<option>Last 2 Months</option>
												<option>Last Months</option>
												<option>Last Weeks</option>
												<option>Last 3 Days</option>
											</select>
											<div className="float-right p-tb5 p-r10">
												<Link to={"/browse-job-filter-list"} className="p-lr5"><i className="fa fa-th-list"></i></Link>
												<Link to={"/browse-job-filter-grid"} className="p-lr5"><i className="fa fa-th"></i></Link>
											</div>
										</div>
									</div>
									{
						loading?
						<div >
							<Loader />
							</div>
						:
									<Handimans itemsPerPage={10} />
}
								
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Browsejobfilterlist;