import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/userHeader'; 
import Footer from './../Layout/Footer'; 
import Profilesidebar from './../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {ChangePassword} from '../../queries'
import swal from 'sweetalert';

function Changepasswordpage(){
	const [form , setForm] = useState({password: '', confirm: ''})
	const submitFunc = async(e) => {
		e.preventDefault()
		try {
			if(form.password !== '' && form.confirm !==''){
			const variables = {
				password: form.password,
				confirm: form.confirm
			}
			if(form.password !== form.confirm){
				return 	swal({
					title: "Error Message",
					text: "Password and confirm password must be the same",
					icon: "warning",
					dangerMode: true,
				  })
			}
			const data = await graphQLClient.request(ChangePassword,variables)
			if(data){
				swal({
					title: "Sucessful Request",
					text: "Your message has been sent sucessfully, we will respond as soon as possible",
					icon: "success",
					dangerMode: true,
				  })
				  setForm({password: '', confirm: ''})
			}
			}else{
				swal({
					title: "Error Message",
					text: "Invalid input details provided",
					icon: "warning",
					dangerMode: true,
				  })
			}
			
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			swal({
				title: "Error Message",
				text: "An error occured message was not sent try again",
				icon: "warning",
				dangerMode: true,
			  })
		}
	}
	return(
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
							<Profilesidebar />
								<div className="col-xl-9 col-lg-8 m-b30">
									<div className="job-bx job-profile">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Change Password</h5>
											<Link to={"/jobs-cv-manager"} className="site-button right-arrow button-sm float-right">Back</Link>
										</div>
										<form>
											<div className="row">
												<div className="col-lg-6">
													<div className="form-group">
														<label>New Password </label>
														<input type="password" className="form-control" value={form.password} onChange={(e)=> setForm({...form, password: e.target.value})}/>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<label>Confirm New Password</label>
														<input type="password" className="form-control" value={form.confirm} onChange={(e)=> setForm({...form, confirm: e.target.value})} />
													</div>
												</div>
												<div className="col-lg-12 m-b10">
													<button className="site-button" onClick={(e)=> submitFunc(e)}>Update Password</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Changepasswordpage;