import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import {connect} from 'react-redux'
import { Redirect} from "react-router-dom";
import {FILTER_USERS,LOAD_STATES,LOAD_CITIES } from '../../store/actions';
import axios from 'axios'
var bnr1 = require('./../../images/background/pexels-leticia-ribeiro-2249290.jpg');

class IndexBanner extends Component{
	constructor(){
		super();
		this.state = {skill: '', location:'', city: '', redirect: false, states: [], cities: []};
		this.searchFuc = this.searchFuc.bind(this)
		this.selectState = this.selectState.bind(this)
		
	}
	
	searchFuc(e){
		e.preventDefault()
		this.props.dispatch(FILTER_USERS(this.state));
		console.log(this.state)
		this.setState({redirect: true})
	}
	// Select State
selectState (state){
	this.setState({location: state})
	try {
		 fetch(`https://locus.fkkas.com/api/regions/${state}`).then(async (res)=>{
			const cities = await res.json()
			this.props.dispatch(LOAD_CITIES(cities.data));
		console.log(cities.data)
			})
		// let left = state.split(" ")[0]
		// axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {  "country": "Nigeria",
		// "state": left},{ 'Content-Type': 'application/json'}).then((data)=>{
		// this.props.dispatch(LOAD_CITIES(data.data.data));
		// })
		
	} catch (error) {
		console.log(error)
	}
	
}
	componentWillMount(){
		const func = async() =>{
			await  fetch("https://locus.fkkas.com/api/states").then(async (res)=>{
			const states = await res.json()
			this.props.dispatch(LOAD_STATES(states.data));
		console.log(states)
			})
			
			
		}
	func()

	}
	componentDidMount() {
		var i = 0;
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
		// Placeholder Animation End
	}
	render(){
		const { redirect} = this.state;
		if(redirect){
			return <Redirect to={{pathname: "/handimans",search: `?utm=${this.state.location}`,state: { previousLocation: '/home', searched: true, search: this.state}}}/>
		}
		return(
			<div className="dez-bnr-inr dez-bnr-inr-md" style={{backgroundImage:"url("+ bnr1 +")" }}>
				<div className="container">
					<div className="dez-bnr-inr-entry align-m">
						<div className="find-job-bx">
							<Link to={"/browse-job"} className="site-button button-sm">Find skilled personnel, Freelancers & Handimen</Link>
							<h2 style={{color:'#fff'}}>Search Between More Then <br/> <span className="text-primary">50,000</span> Handimen.</h2>
							<form className="dezPlaceAni">
								<div className="row">
									<div className="col-lg-4 col-md-4">
										<div className="form-group">
											<label>Skill/Trade, Keywords, or Phrase</label>
											<div className="input-group">
												<input type="text" className="form-control" placeholder="" value={this.state.skill} onChange={(e)=> this.setState({skill: e.target.value})}/>
												<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-search"></i></span>
												</div>
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-3">
										<div className="form-group">
										<div className="input-group">
											<Form.Control as="select" custom className="select-btn" value={this.state.location} onChange={(e)=>
												this.selectState(e.target.value) 
												
												 }>
												<option value=" ">Select State</option>
												{
													this.props.states.map((item, index)=>(
													<option key={index} value={item.alias}>{item.name}</option>
													))
												}
											</Form.Control>
											<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-map-marker"></i></span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-3">
										<div className="form-group">
										<div className="input-group">
											<Form.Control as="select" custom className="select-btn" value={this.state.city} onChange={(e)=> this.setState({city: e.target.value})}>
												<option value=" ">Select City</option>
												{
													this.props.cities.map((item, index)=>(
													<option key={index} value={item.name}>{item.name}</option>
													))
												}
											</Form.Control>
											<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-map-marker"></i></span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-2 col-md-2">
										<button type="submit" to="/browse-job" className="site-button btn-block" onClick={this.searchFuc}>Find Handiman</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { web } = state
	return web
  }
export default connect(mapStateToProps)(IndexBanner);