import React, {useState, useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from './../Layout/userHeader';
import Footer from './../Layout/Footer';
import {Modal} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import Profilesidebar from './../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {userDetail,AddSkill,CreateExprience,CreateProject} from '../../queries';
import {LOAD_USER_DETAILS,ADD_USER_SkILL,ADD_USER_EXPRIENCE,ADD_USER_PORTFOLIO,ADD_USER_PROJECT,ADD_USER_PROVE } from '../../store/actions'
import {useMutation, gql} from "@apollo/client"
import swal from 'sweetalert';
import DatePicker from 'react-date-picker';
import {FormatDate} from 'react-date-time-formatter'

var bnr = require('./../../images/banner/bnr1.jpg');

//var bnr2 = require('./../../images/background/bg3.jpg');

const PortfolioUpload = gql`
 mutation singleUpload($file: Upload! ) {
	singleUpload(file: $file) {
		id
		url
		createdAt
  }
}
`;
const proveUpload = gql`
 mutation uploadProve($file: Upload! ) {
	uploadProve(file: $file) {
		id
		url
		createdAt
  }
}
`;
function Jobmyresume(){
	const [exprience , setExprience] = useState({designation: '', organization: '', owner: '',sdate: new Date(),edate: new Date(), syear: '', smonth: '', eyear: '', emonth: '', des: ''})
	const [project , setProject] = useState({title: '', client: '', status: '',sdate: new Date(),edate: new Date(),  des: ''})
	const userDetails = useSelector((state)=> state.user)
	console.log(userDetails)
	// const userResume = useSelector((state)=> state.resume)		
	const [keyskill, setKeyskill] = useState(false);	
	const [employment, setEmployment] = useState(false);		
	const [projects, setProjects] = useState(false);				
	const [skill, setSkill] = useState('')
	const dispatch = useDispatch()
	const func = async() => {
		try {
			const data = await graphQLClient.request(userDetail)
			console.log(data.user)
			const {id, 
				username,
				isverified,
				 name, 
				 email, 
				 phone, 
				 city,
				 state,
				  pic,
				  title,
				  language,
				  age,
				salary,
				expected,
				description,
				country,
				postalcode,
				address,
				projects,
				expriences,
				skills,
				uploads,
				proves,
				payment_status,
				unread_chats
			} = data.user
			dispatch(LOAD_USER_DETAILS({
				id: id,
				isverified: isverified,
				username: username,
				name: name,
				email: email,
				phone: phone,
				country: country,
				image: pic,
				title: title,
				language: language,
				age: age,
				salary: salary,
				expected: expected,
				description: description,
				postalcode: postalcode,
				city: city,
				state: state,
				address: address,
				exprience: expriences,
				projects: projects,
				skills: skills,
				portfolio: uploads,
				prove: proves,
				payment_status: payment_status,
				unread_chats: unread_chats?.length,

			}))
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
		}
	} 

	useEffect(()=>{
	func()
	},[])
	const addSkill = async(e) =>{
		e.preventDefault()
	
		const variables = {
			skill: skill 
		  }
		let data = await graphQLClient.request(AddSkill,variables).catch((e)=> console.log(e))
		dispatch(ADD_USER_SkILL(data.addSkill))
		setKeyskill(false)

	}
	const saveEmployment = async() =>{
		// console.log(exprience)
		let variables ={
			designation: exprience.designation,
			organization: exprience.organization,
			owner: exprience.owner,
			sdate: exprience.sdate,
			edate: exprience.edate,
			des: exprience.des
		}
		let data = await graphQLClient.request(CreateExprience,variables).catch((e)=> console.log(e))
		console.log(data)
		dispatch(ADD_USER_EXPRIENCE(exprience))
		setEmployment(false)
		// console.log(userDetails.exprience)
	}
	const addProject = async() =>{
		// console.log(project)
		let variables ={
			title: project.title,
			client: project.client,
			status: project.status,
			sdate: project.sdate,
			edate: project.edate,
			des: project.des
		}
		let data = await graphQLClient.request(CreateProject,variables).catch((e)=> console.log(e))
		console.log(data)
		dispatch(ADD_USER_PROJECT(project))
		setProjects(false)
		// console.log(userDetails.projects)
	}
	const [uploadFile] = useMutation(PortfolioUpload,{
		onCompleted: (data)=>  {
			console.log(data.singleUpload)
			dispatch(ADD_USER_PORTFOLIO(data.singleUpload))
		},
		onError: ()=> 	swal({
			title: "Error Message",
			text: "An error has occured during the upload, please try again with a smaller image",
			icon: "error",
		  })
	})
	const [uploadProve] = useMutation(proveUpload,{
		onCompleted: (data)=>  {
			console.log(data)
			dispatch(ADD_USER_PROVE(data.uploadProve))
		},
		onError: ()=> 	swal({
			title: "Error Message",
			text: "An error has occured during the upload, please try again with a smaller image",
			icon: "error",
		  })
	})
	var uploadImageToStorage = async ({
		target: {
		  validity,
		  files: [file]
		}
	  }) => {

		console.log(file)
		validity.valid && uploadFile({ variables: { file } })
	  }
	  var uploadToStorage = async ({
		target: {
		  validity,
		  files: [file]
		}
	  }) => {

		console.log(file)
		validity.valid && uploadProve({ variables: { file } })
	  }
	  const strength = () =>{
		if(!userDetails.payment_status  && !userDetails.isverified  && userDetails.phone == ""){
			return 10
		}else if(userDetails.payment_status  && userDetails.isverified  && userDetails.phone !==""){
			return 100
		}else if(!userDetails.payment_status  && !userDetails.isverified  && userDetails.phone !==""){
			return 40
		}else if(userDetails.payment_status  && userDetails.isverified  && userDetails.phone ==""){
			return 90
		}else if(!userDetails.payment_status  && userDetails.isverified  && userDetails.phone !==""){
			return 70
		}else if(!userDetails.payment_status  && userDetails.isverified  && userDetails.phone ==""){
			return 40
		}
		else if(userDetails.payment_status  && !userDetails.isverified  && userDetails.phone !==""){
			return 70
		}else if(userDetails.payment_status  && !userDetails.isverified  && userDetails.phone ==""){
			return 40
		}
	  }
	 console.log( strength())
	return(
		<>
			<Header />
			<div className="page-content">
				
				<div className="overlay-black-dark profile-edit p-t50 p-b20" style={{backgroundImage:"url("+bnr+")"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-8 col-md-7 candidate-info">
								<div className="candidate-detail">
									<div className="canditate-des text-center">
										<Link to={"#"}>
											<img alt="" src={userDetails.image} />
										</Link>
										{/* <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
											<input type="file" className="update-flie" />
											<i className="fa fa-camera"></i>
										</div> */}
									</div>
									<div className="text-white browse-job text-left">
										<h4 className="m-b0">{userDetails.name}
										</h4>
										<p className="m-b15">{userDetails.title}</p>
										<ul className="clearfix">
											<li><i className="ti-location-pin"></i> {userDetails.city}, {userDetails.country}</li>
											<li><i className="ti-mobile"></i> {userDetails.phone}</li>
											<li><i className="ti-briefcase"></i> Fresher</li>
											<li><i className="ti-email"></i> {userDetails.email}</li>
										</ul>
										<div className="progress-box m-t10">
											<div className="progress-info">Profile Strength (Average)<span> {strength()}%</span></div>
											<div className="progress">
												<div className="progress-bar bg-primary" style={{width: `${strength()}%`,}} role="progressbar" ></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{
								!userDetails.payment_status  || !userDetails.isverified  || userDetails.phone == ""?
							<div className="col-lg-4 col-md-5">
								{/* <Link to={"#"}> */}
									<div className="pending-info text-white p-a25">
										<h5>Pending Action</h5>
										<ul className="list-check secondry">
											{
											userDetails?.phone? 
											null
												:
												<Link to={"./profile"} style={{textDecoration:'none', color:"#fff"}}>
											<li>Add Mobile Number</li>
											</Link>
											}
											{
											userDetails?.isverified? 
											null
											:
											<Link to={"./email-verification"} style={{textDecoration:'none', color:"#fff"}}>
										<li>Email verification</li>
										</Link>
											}
											{
											userDetails?.payment_status? 
											null
											:
											<Link to={"./email-verification"} style={{textDecoration:'none', color:"#fff"}}>
										<li>Account Activation</li>
										</Link>
											}
											
										</ul>
									</div>
								{/* </Link> */}
							</div>
							:
							null
							}
							

						</div>
					</div>

					
				</div>
				
				<div className="content-block">
					<div className="section-full browse-job content-inner-2">
						<div className="container">
							<div className="row">
							<Profilesidebar  hide={true}/> 
								{/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
								
								</div> */}
								<div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
								<div id="profile_summary_bx" className="job-bx bg-white m-b30" >
										<div className="d-flex">
											<h5 className="m-b15">Profile Summary</h5>
										</div>
										<p className="m-b0">{userDetails.description}</p>
									</div>

									<div id="key_skills_bx" className="job-bx bg-white m-b30">
										<div className="d-flex">
											<h5 className="m-b15">Key Skills</h5>
											<Link to={"#"} data-toggle="modal" data-target="#keyskills" onClick={()=>setKeyskill(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Add</Link>
										</div>
										<div className="job-time mr-auto">
											{
												userDetails.skills.map((item, index)=>(
													<>
													<Link to={"#"} className="mr-1" key={index}><span>{item.skills}</span></Link>
													</>
												))
											}
										</div>
										
										<Modal show={keyskill} onHide={setKeyskill} className="modal fade modal-bx-info editor">
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" id="KeyskillsModalLongTitle">Key Skills</h5>
														<button type="button" className="close" onClick={()=>setKeyskill(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<p>It is the first thing recruiters notice in your profile. Write concisely what makes you unique and right person for the job you are looking for.</p>
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<input type="text" className="form-control tags_input" defaultValue={skill} value={skill} onChange={(e)=> setSkill(e.target.value)}/>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setKeyskill(false)}>Cancel</button>
														<button type="button" className="site-button" onClick={(e)=> addSkill(e)}>Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
									<div id="employment_bx" className="job-bx bg-white m-b30 ">
										<div className="d-flex">
											<h5 className="m-b15">Exprience</h5>
											<Link to={"#"} onClick={()=>setEmployment(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Add</Link>
										</div>
										{
											userDetails.exprience.map((item,index)=> (
											<div key={index}>
										<h6 className="font-14 m-b0">{item.designation}</h6>
										<p className="m-b0">{item.organization}</p>
										<p className="m-b0"><FormatDate offsetDay={true}>{item.sdate}</FormatDate>  to <FormatDate offsetDay={true}>{item.edate}</FormatDate></p>
										<p className="m-b0">{item.des}</p>
											</div>	
											))
										}
									
										
										<Modal show={employment} onHide={setEmployment} className="modal fade modal-bx-info editor" >
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" id="EmploymentModalLongTitle">Add Exprience</h5>
														<button type="button" className="close" onClick={()=>setEmployment(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Your Designation</label>
																		<input type="text" className="form-control" placeholder="Enter Your Designation" value={exprience.designation} onChange={(e)=> setExprience({...exprience, designation: e.target.value}) } />
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Your Organization</label>
																		<input type="email" className="form-control" placeholder="Enter Your Organization" value={exprience.organization} onChange={(e)=> setExprience({...exprience, organization: e.target.value}) }/>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Is this your current company?</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="employ_yes" name="example1" value={exprience.owner} onChange={(e)=> setExprience({...exprience, owner: "yes"}) } />
																					<label className="custom-control-label" htmlFor="employ_yes">Yes</label>
																				</div>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="employ_no" name="example1" value={exprience.owner} onChange={(e)=> setExprience({...exprience, owner: "no"}) }/>
																					<label className="custom-control-label" htmlFor="employ_no">No</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Started Working From</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<DatePicker className="form-control" format={"y-MM-dd"} onChange={(e)=> setExprience({...exprience, sdate: e}) } value={exprience.sdate} />
																			</div>
																			
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Worked Till</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6" value={exprience.eyear} onChange={(e)=> setExprience({...exprience, eyear: e.target.value}) }>
																			<DatePicker className="form-control" format={"y-MM-dd"} onChange={(e)=> setExprience({...exprience, edate: e}) } value={exprience.edate} />
																			</div>
																			
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Describe your Job Profile</label>
																		<textarea className="form-control" placeholder="Type Description" value={exprience.des} onChange={(e)=> setExprience({...exprience, des: e.target.value}) }></textarea>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setEmployment(false)} >Cancel</button>
														<button type="button" className="site-button" onClick={(e)=> saveEmployment(e)}>Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
			
									
									<div id="projects_bx" className="job-bx bg-white m-b30">
										<div className="d-flex">
											<h5 className="m-b15">Projects</h5>
											<Link to={"#"} onClick={()=>setProjects(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Add</Link>
										</div>
										{
											userDetails.projects.map((item, index)=> (
												<div key={index}>
										<h6 className="font-14 m-b0">{item.title}</h6>
										<p className="m-b0">{item.client}</p>
										<FormatDate offsetDay={true}>{item.sdate}</FormatDate>   to  <FormatDate offsetDay={true}>{item.edate}</FormatDate>
										{/* <p className="m-b0">{item.sdate}  to {item.edate}</p> */}
										<p className="m-b0">{item.des}</p>
										<strong> {item.status}</strong>
												</div>
											))
										}
									
										
										<Modal className="modal fade modal-bx-info editor" show={projects} onHide={setProjects}> 
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" id="ProjectsModalLongTitle">Add Projects</h5>
														<button type="button" className="close" onClick={()=>setProjects(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Project Title</label>
																		<input type="text" className="form-control" placeholder="Enter Project Title" value={project.title} onChange={(e)=> setProject({...project, title: e.target.value}) }/>
																	</div>
																</div>
																{/* <div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Tag this project with your Employment/Education</label>
																		<select>
																			<option>Class 12th</option>
																			<option>Class 10th</option>
																		</select>
																	</div>
																</div> */}
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Client</label>
																		<input type="text" className="form-control" placeholder="Enter Client Name" value={project.client} onChange={(e)=> setProject({...project, client: e.target.value}) }/>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Project Status</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="inprogress" name="example1" value={project.status} onChange={(e)=> setProject({...project, status: 'in progress'}) }/>
																					<label className="custom-control-label" htmlFor="inprogress">In Progress</label>
																				</div>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="finished" name="example1" value={project.status} onChange={(e)=> setProject({...project, status: 'finish'}) }/>
																					<label className="custom-control-label" htmlFor="finished">Finished</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Started Working From</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<DatePicker className="form-control" format={"y-MM-dd"} onChange={(e)=> setProject({...project, sdate: e}) } value={project.sdate} />
																			
																			</div>

																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Worked Till</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<DatePicker className="form-control" format={"y-MM-dd"} onChange={(e)=> setProject({...project, edate: e}) } value={project.edate} />
																			</div>
																			
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Details of Project</label>
																		<textarea className="form-control" placeholder="Type Description" value={project.des} onChange={(e)=> setProject({...project, des: e.target.value}) }></textarea>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setProjects(false)}>Cancel</button>
														<button type="button" className="site-button" onClick={(e)=> addProject(e)}>Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
								
									<div id="exprience_bx" className="job-bx bg-white m-b30 ">
										<div className="d-flex">
											<h5 className="m-b15">Portfolio</h5>
											<div className="site-button add-btn button-sm" title="update" data-toggle="tooltip" data-placement="right">
											<input type="file" className="update-flie" onChange={(e)=> uploadImageToStorage(e)} />
											<i className="fa fa-camera"></i>
										</div>
										
										</div>

										<div className="row">
										{userDetails.portfolio.map((item,index)=>(
							<li style={{listStyle: 'none'}} className="card-container col-lg-4 col-md-3 col-sm-6 col-6 advertising design photography" key={index}>
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect ">
									<Link to ={"#"}> <img src={item.url}  alt="" /> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<a className="mfp-link" title="Image Title Come Here" href="images/gallery/masonry/img6.jpg"> <i className="fa fa-trash"></i> </a>	
											</div>
										</div>
									</div>
								</div>
							</li>
						))}	
							</div>
										
									</div>
									<div id="exprience_bx" className="job-bx bg-white m-b30 ">
										<div className="d-flex">
											<h5 className="m-b15">Prove Of location</h5>
											<div className="site-button add-btn button-sm" title="update" data-toggle="tooltip" data-placement="right">
											<input type="file" className="update-flie" onChange={(e)=> uploadToStorage(e)} />
											<i className="fa fa-camera"></i>
										</div>
										
										</div>

										<div className="row">
										{userDetails.prove.map((item,index)=>(
							<li style={{listStyle: 'none'}} className="card-container col-lg-4 col-md-3 col-sm-6 col-6 advertising design photography" key={index}>
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect ">
									<Link to ={"#"}> <img src={item.url}  alt="" /> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<a className="mfp-link" title="Image Title Come Here" href="images/gallery/masonry/img6.jpg"> <i className="fa fa-trash"></i> </a>	
											</div>
										</div>
									</div>
								</div>
							</li>
						))}	
							</div>
										
									</div>
								<div id="personal_details_bx" className="job-bx bg-white m-b30">
									<div className="d-flex">
										<h5 className="m-b30">Personal Details</h5>
										<Link to={"./profile"}  className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Add</Link>
									</div>
									

									
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Age</label>
												<span className="clearfix font-13">{userDetails.age}</span>
											</div>
											{/* <div className="clearfix m-b20">
												<label className="m-b0">Gender</label>
												<span className="clearfix font-13">male</span>
											</div> */}
											{/* <div className="clearfix m-b20">
												<label className="m-b0">Marital Status</label>
												<span className="clearfix font-13">Single / unmarried</span>
											</div> */}
											<div className="clearfix m-b20">
												<label className="m-b0">Number</label>
												<span className="clearfix font-13">{userDetails.phone}</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Differently Abled</label>
												<span className="clearfix font-13">None</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Languages</label>
												<span className="clearfix font-13">{userDetails.language}</span>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Permanent Address</label>
												<span className="clearfix font-13">{userDetails.address}</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Postal Code</label>
												<span className="clearfix font-13">{userDetails.postalcode}</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">City</label>
												<span className="clearfix font-13">{userDetails.city}</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Work permit of other country</label>
												<span className="clearfix font-13">{userDetails.country}</span>
											</div>
										</div>
									</div>	
								</div>
								
							</div>
						</div>
					</div>
					
				</div>
				
			</div>	
				
			</div>	
			<Footer />
		</>
	)
}
export default Jobmyresume;