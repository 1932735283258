import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
// import {Modal} from 'react-bootstrap';
// import Logout from './Logout';

import logo2 from './../../images/logo.png';
// var bnr3 = require('./../../images/background/bg3.jpg');


class Header extends Component{
	constructor(props){
		super(props);
		// console.log(props)
	}
	state = {
		// initial state
		show: false,
	}

	handleClose = () => {
		this.setState({ show: false });
	};
	handleShow = () => {
		this.setState({ show: true });
	};	
	componentDidMount() {
        // sidebar open/close
	console.log(this.props)
        var Navicon = document.querySelector('.navicon');
        var sidebarmenu = document.querySelector('.myNavbar ');

        function toggleFunc() {
            sidebarmenu.classList.toggle('show');
         //   Navicon.classList.toggle('open');
        }
        Navicon.addEventListener('click', toggleFunc);

        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }
		
        function checkLi(current) {
            current.parentElement.parentElement.querySelectorAll( "li" ).forEach( el =>
				(current.parentElement !== el) ? el.classList.remove('open') : ''
			);
			setTimeout(() => {
				current.parentElement.classList.toggle('open');
			}, 100);			
        }
	}	
	render(){	
		return(
			<>
				<header className="site-header mo-left header fullwidth">
					<div className="sticky-header main-bar-wraper navbar-expand-lg">
						<div className="main-bar clearfix">
							<div className="container clearfix">
								
								<div className="logo-header mostion">
									<Link to={"/"}><img src={logo2} className="logo" alt="img" /></Link>
								</div>
								
								 <button className="navbar-toggler collapsed navicon  justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button> 
								
								<div className="extra-nav">
									<div className="extra-cell" style={{positon: 'relative'}}>
									<Link to={'/chats'} ><i className="ti-comments" aria-hidden="true" style={{fontSize: "30px"}}></i> </Link>
									<p style={{padding: "1px 8px", fontSize: "8px", borderRadius:'50%',backgroundColor:"green", textAlign:'center', position: 'absolute', top: "10px", color:'#fff', left:'5px'}}>{this.props.unread_chats?this.props.unread_chats:this.props.unread_chats}</p>
									</div>
								</div>
								 
								<div className="header-nav navbar-collapse collapse myNavbar justify-content-start" id="navbarNavDropdown"> 
									<div className="logo-header mostion d-md-block d-lg-none">
										<Link to={'/'} className="dez-page"><img src={logo2} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">
										<li className=""> 
										
											<Link to={'./'} >Home 
											</Link>
										</li>
										<li >
											<Link to={'/handimans'} > 
											Find handiman
											</Link>
										</li>
										<li >
											<Link to={'/login'} >New Feeds
											</Link>
										</li>
										<li>
											<Link to={'/contact'}>Contact Us
											</Link>
										</li>
										<li>
											<Link to={'/about-us'}>About Us
											</Link>
										</li>
									</ul>			
								</div>
							</div>
						</div>
					</div>
				</header>
				{/*  Model Start */}

				{/*  Model END */}
			</>
		)
	}
}
function mapStateToProps(state) {
	const { user } = state
	return user
  }
export default connect(mapStateToProps)(Header);

