import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/userHeader'; 
import Footer from '../Layout/Footer'; 
import Profilesidebar from '../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {userDetail,thread} from '../../queries'
import {LOAD_USER_DETAILS,LOAD_THREAD } from '../../store/actions'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../Element/Loader';
import {FormateDateTime,FormatMonth,FormatDay,FormatYear} from 'react-date-time-formatter'

function Chats(){
	const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
	const store = useSelector((state)=> state.chat)
	const user = useSelector((state)=> state.user)
    const func = async() => {
		try {
			const data = await graphQLClient.request(userDetail)
			// console.log(data.user)
			const {id, 
				isverified,
				username,
				 name, 
				 email, 
				 phone, 
				 city,
				 state,
				  pic,
				  title,
				  language,
				  age,
				salary,
				expected,
				description,
				country,
				postalcode,
				address,
				projects,
				expriences,
				skills,
				uploads,
				proves,
				payment_status,
				unread_chats
			} = data.user
			dispatch(LOAD_USER_DETAILS({
				id: id,
				isverified: isverified,
				name: name,
				username: username,
				email: email,
				phone: phone,
				country: country,
				image: pic,
				title: title,
				language: language,
				age: age,
				salary: salary,
				expected: expected,
				description: description,
				postalcode: postalcode,
				city: city,
				state: state,
				address: address,
				exprience: expriences,
				projects: projects,
				skills: skills,
				portfolio: uploads,
				prove: proves,
				payment_status: payment_status,
				unread_chats: unread_chats?.length,

			}))
			await graphQLClient.request(thread).then((data)=> dispatch(LOAD_THREAD(data.chats)) ).catch((e)=> console.log(e))
		setLoading(false)
		// console.log(user)
	
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 
//  AM/PM formatter
function formatAMPM(string) {
    var date = new Date(string)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
    useEffect(()=>{
        func()
        },[])
	return(
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
                    {
							loading?
							<div >
								<Loader />
								</div>
							:
	<>
					<div className="section-full bg-white browse-job p-b50">
						<div className="container">
							<div className="row">
							<Profilesidebar />
								<div className="col-xl-9 col-lg-8">
									<div className="m-b30">
										<input type="text" className="form-control" placeholder="Search chat messages" />
									</div>
									<ul className="post-job-bx">

										{store.thread.map((item,index)=>(
											<li key={index}>
												<div className="post-bx">
													<div className="d-flex m-b30">
														<div className="job-post-company">
															<Link to= {''}><span>
																<img alt="" src={item.senderDetails?.pic? item.senderDetails?.pic : require('../../images/logo/icon2.png')} />
															</span></Link>
														</div>
														<div className="job-post-info">
															<h4><Link to={{
															pathname:"/in-app-chat",
															//  search:'?sort=name',
															//  hash:'#the-hash',
															state: {previousLocation: '/chats', userid: item.senderDetails?.id, user: item.senderDetails}
														}}>{item.senderDetails?.name}</Link></h4>
															<ul>
														<p>
															{
															item.type !=="text"?
															<label className="like-btn">
															{item.sender_id == user.username? "You:": null} <i className="fa fa-paper-clip" ></i>
														  </label>
																:
																item.sender_id == user.username? `You: ${item.message}`: item.message	
															}
															
														</p>
															</ul>
														</div>
													</div>
													<div className="d-flex">
														<div className="job-time mr-auto">
														
														</div>
														<div className="salary-bx">
															<FormateDateTime
															show={false}
																resultIsIn={(props)=>{
																	console.log("result",props)
																	const conIsIn = props.toLowerCase()
																	if(conIsIn === 'month'){
																		return   (<div>{formatAMPM(item.createdAt)}, <FormatDay offset={true}> {item.createdAt} </FormatDay>  <FormatMonth > {item.createdAt} </FormatMonth></div> )
																	  }else if(conIsIn === 'year'){
																		return  ( <div>{formatAMPM(item.createdAt)},  <FormatMonth > {item.createdAt} </FormatMonth>  <FormatYear >{item.createdAt} </FormatYear></div>)
																	  }
																	  else if(conIsIn === 'day'){
																		return  (<div> <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></div> )
																	  }else if(conIsIn === 'week'){
																		return (<div>{formatAMPM(item.createdAt)},  <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></div> )
																	  }else{
																		return (<div>{formatAMPM(item.createdAt)}, Today</div> )
																	  }
																	
																}}
															>
																{item.createdAt}
																</FormateDateTime>
															{/* <span>{item.createdAt}</span> */}
														</div>
													</div>
													<label className="like-btn">
														  {item.unread.length}
													</label>
												</div>
											</li>
										))}
									</ul>
									{/* <div className="pagination-bx m-t30">
										<ul className="pagination">
											<li className="previous"><Link to ={'#'}><i className="ti-arrow-left"></i> Prev</Link></li>
											<li className="active"><Link to ={'#'}>1</Link></li>
											<li><Link to ={'#'}>2</Link></li>
											<li><Link to ={'#'}>3</Link></li>
											<li className="next"><Link to ={'#'}>Next <i className="ti-arrow-right"></i></Link></li>
										</ul>
									</div> */}
								</div>
							
							</div>
						</div>
					</div>
                    </>						
}
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Chats;