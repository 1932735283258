
import {REFRESH} from './queries'
import {graphQLClient}  from './client';
import Spinner from './markup/Element/Spinner';

import React,{ useState, createContext, useContext, useEffect } from "react";


export const CustomerContext = createContext([]);

export const useCustomer = () => useContext(CustomerContext);

export const CustomerWrapper = ({ children }) => {
  const [customer, setCustomer] = useState(null);
  const [working, setWorking] = useState(true);
  const [username, setUsername] = useState(null);
  const refreshToken = () => {
    graphQLClient
      .request(REFRESH)
      .then(({ refresh_token: { minutes, role, username} }) => {
      
        setTimeout(() => {
          refreshToken()
        }, minutes)

        setCustomer(role);
        setUsername(username)
      })
      .catch(console.log)
      .finally(() => {
        setWorking(false);
      });
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
 
  }, []);

  return (
    <CustomerContext.Provider value={{ customer, setCustomer,username, setUsername}}>
      {working ?
       <div style={{ display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', height: '100vh', width: '100%'}}>
        <Spinner />
       </div>
       :
        children
        }
    </CustomerContext.Provider>
  );
};