const initState = {
	curRecipient: {
		image: '',
		username: '',
		name:'',
		status:''
	},
	thread:[],
	count: 0,
	msg:[
		// {
		// 	id: "1313",
		// 	sender_id:"1",
		// 	sender:"Samuel Momoh",
		// 	recieverId:'2',
		// 	recieverName:'James Sunday',
		// 	message:"This a test",
		// 	period:"10:14 AM, Today"
		// },
		// {
		// 	id: "1313",
		// 	sender_id:"2",
		// 	sender:"James Sunday",
		// 	recieverId:'2',
		// 	recieverName:'Samuel Momoh',
		// 	message:"I recieved your message",
		// 	period:"10:14 AM, Today"
		// },
	],
}


const ChatReducer = (state=initState,action) =>{

switch(action.type){
	case "LOAD_THREAD":
		// console.log(action.payload)
        return {
                ...state,
                thread: action.payload 
            }
	case "LOAD_MSG":
		// console.log(action.payload)
        return {
                ...state,
                msg: action.payload 
            }
	case "SEND_MSG":
		console.log(action.payload)
        return {
                ...state,
                msg: [...state.msg, action.payload] 
            }
			case "UPDATE_MSG_URL":
		console.log(state.msg)
	
		let index = state.msg.findIndex(object => {
			return object.file?.url === action.payload.tem;
		  });
		  state.msg[index].file.url = action.payload.saved
		// console.log(index)
        return state
    case "OTHER_MSG":
		 var new_thread = state.thread
		 var threadExist = state.thread.findIndex(object => {
			return object.sender_id === action.payload.sender_id;
		  });
		  if(threadExist !==-1){
			 new_thread[threadExist].unread	= [...state.thread[threadExist].unread, action.payload]		
			

		  }else{
			  var new_data = {...action.payload, unread: [action.payload]}
			return{
                ...state,
				thread: [...state.thread, new_data]
            }
		  }

        return {
                ...state,
				thread: new_thread
            }
			case "READ_ALL":
				var msg = action.payload
				var new_msg = state.msg;
				new_msg.map((element)=>{
					msg.map((item)=>{
						// console.log(element.message_id, item.message_id)
								if(element.message_id == item.message_id){
						return	element.status = 'true'
						}
					})
				})
				// console.log(new_msg)
				return {
						...state,
						msg: new_msg
					}
					case "READ_ONE":
						 msg = action.payload
						 new_msg = state.msg;
						let msgExist = new_msg.findIndex(object => {
							return object.message_id === msg.message_id;
						  })
						  new_msg[msgExist].status = true
					// console.log(new_msg)
					// 	console.log(msgExist)
						return {
								...state,
								msg: new_msg
							}
			case "SET_RECIPIENT":
				console.log(action.payload)
				return {
						...state,
						curRecipient: action.payload
					}
	
									   
	default:
		return state
}
}
export default ChatReducer