

const initState = {
	id:'',
	isverified: false,
	name: '',
	username: '',
	lastName:'',
	email:'',
	phone:'',
	image:'',
	role: '',
	title:'',
    language:'',
    age:'',
    salary:'',
    expected:'',
    description:'',
    country:'',
    postalcode:'',
	payment_status: false,
    city:'',
	state: '',
    address:'',
	exprience:[],
	projects:[],
	skills: [],
	portfolio: [],
	prove: [],
	states: [],
	cities: [],
	unread_chats: null,
	unread_chats_data: []
}


const UserReducer = (state=initState,action) =>{

switch(action.type){
	case "LOAD_USER_DETAILS":
		console.log(action.payload)
		return {
			...state,
				id: action.payload.id,
				name: action.payload.name,
				username: action.payload.username,
				email: action.payload.email,
				phone: action.payload.phone,
				image: action.payload.image,
				title: action.payload.title,
				language: action.payload.language,
				age: action.payload.age,
				salary: action.payload.salary,
				expected: action.payload.expected,
				description: action.payload.description,
				country: action.payload.country,
				postalcode: action.payload.postalcode,
				city: action.payload.city,
				state: action.payload.state,
				address: action.payload.address,
				exprience: action.payload.exprience,
				projects: action.payload.projects,
				skills: action.payload.skills,
				portfolio: action.payload.portfolio,
				prove: action.payload.prove,
				isverified: action.payload.isverified,
				payment_status:    action.payload.payment_status,
				unread_chats: action.payload.unread_chats,
				unread_chats_data: action.payload.unread_chats_data
		}
		case "EDIT_USER_DETAILS":
		    return {
		        ...state,
		    	name: action.payload.name,
		        email: action.payload.email,
		        phone: action.payload.phone,
		        country: action.payload.country,
				title: action.payload.title,
				language: action.payload.language,
				age: action.payload.age,
				salary: action.payload.salary,
				expected: action.payload.expected,
				description: action.payload.description,
				postalcode: action.payload.postalcode,
				city: action.payload.city,
				state: action.payload.state,
				address: action.payload.address
		    }
		case "UPLOAD_IMG":
			return {
					...state,
					image: action.payload
				}
						case "EDIT_USER_NAME":
							return {
								 ...state, 
								name: action.payload 
								}
								case "EDIT_USER_STATUS":
							return {
								 ...state, 
								 isverified: action.payload 
								}
										case "EDIT_USER_PHONE":
											return {
												 ...state, 
												phone: action.payload 
												}
												case "EDIT_USER_CITY":
													return {
														 ...state, 
														city: action.payload 
														}
														case "EDIT_USER_STATE":
															return {
																 ...state, 
																state: action.payload 
																}
														case "EDIT_USER_COUNTRY":
															return {
																 ...state, 
																country: action.payload 
																}     
																case "EDIT_USER_TIILE":
																	return {
																		 ...state, 
																		title: action.payload 
																		} 
																		case "EDIT_USER_LANGUAGE":
																			return {
																				 ...state, 
																				language: action.payload 
																				} 
																				case "EDIT_USER_AGE":
																					return {
																						 ...state, 
																						age: action.payload 
																						} 
																						case "EDIT_USER_SALARY":
																							return {
																								 ...state, 
																								salary: action.payload 
																								}            
																								case "EDIT_USER_EXPECTED":
																									return {
																										 ...state, 
																										expected: action.payload 
																										} 
																										case "EDIT_USER_DESCRIPTION":
																											return {
																												 ...state, 
																												description: action.payload 
																												} 
																												case "EDIT_USER_POSTALCODE":
																													return {
																														 ...state, 
																														postalcode: action.payload 
																														} 
																														case "EDIT_USER_ADDRESS":
																															return {
																																 ...state, 
																																address: action.payload 
																																}  
																																case "LOAD_USER_RESUME":
																																	console.log(action.payload)
																																	return {
																																			...state,
																																			skills: [action.payload]
																																		}
																																	case "ADD_USER_SkILL":
																																		return {
																																				...state,
																																				skills: [...state.skills, action.payload] 
																																			}
																																			case "ADD_USER_EXPRIENCE":
																																				console.log(action.payload)
																																		return {
																																				...state,
																																				exprience: [...state.exprience, action.payload] 
																																			}
																																			case "ADD_USER_PROJECT":
																																		return {
																																				...state,
																																				projects: [...state.projects, action.payload] 
																																			}
																																			case "ADD_USER_PORTFOLIO":
																																		return {
																																				...state,
																																				portfolio: [...state.portfolio, action.payload] 
																																			}
																																			case "ADD_USER_PROVE":
																																				return {
																																						...state,
																																						prove: [...state.prove, action.payload] 
																																					}
																																					case "USER_PAYMENT_STATUS":
																																						return {
																																								...state,
																																								payment_status: action.payload
																																							}
																																		
																																			case "USER_LOAD_STATES":
																																				console.log(action.payload)
																																				return {
																																						...state,
																																						states: action.payload
																																					}
																																					case "USER_LOAD_CITIES":
																																				console.log(action.payload)
																																				return {
																																						...state,
																																						cities: action.payload
																																					}
																																					case "MSG_COUNT":
																																						return {
																																								...state,
																																								unread_chats: state.unread_chats + 1
																																							}
																																							case "MSG_COUNT_SUB":
																																						return {
																																								...state,
																																								unread_chats: state.unread_chats - action.payload
																																							}
	default:
		return state
}
}
export default UserReducer