import React, {Component} from 'react';
import Slider from "react-slick";


const postBlog= [
	{
		image: require('./../../images/testimonials/pic1.jpg'),
		msg: "Handiman has created the project opportunity to have clients reach out to me in my Fashion design",
		name: "Helen Opusenibo",
		title: "Fashion Designer"
	},
	{
		image: require('./../../images/testimonials/pic2.jpg'),
		msg: "Before handiman, i could go weeks without getting clients, now i get a new clients on the heels of the other. I have had to expand my business",
		name: "Segun Akinde",
		title: "Welder"
	},
	{
		image: require('./../../images/testimonials/pic3.jpg'),
		msg: "Handiman provides an array of skilled personnel when i need them.",
		name: "Adamu Hawana",
		title: "Employer"
	},
	{
		image: require('./../../images/testimonials/pic2.jpg'),
		msg: "Handiman has created the project opportunity to have clients reach out to me in my Fashion design",
		name: "Helen Opusenibo",
		title: "Fashion Designer"
	},
]


class owltestimonial extends Component{
	render(){
		var settings = {
            slidesToShow: 3,
			arrows : false,
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
				
			 <Slider className="blog-carousel-center owl-carousel owl-none " {...settings}>
				{postBlog.map((item, index) => (
					<div className="item p-3" key={index}>
						<div className="testimonial-5">
							<div className="testimonial-text">
								<p>
									{item.msg}
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<div className="testimonial-pic radius shadow">
									<img src={item.image} width="100" height="100" alt="" />
								</div>
								<strong className="testimonial-name">{item.name}</strong> 
								<span className="testimonial-position">{item.title}</span> 
							</div>
						</div>
					</div>	
				))}
				
			</Slider>
					
				
		)
		
	}
	
}

export default owltestimonial;