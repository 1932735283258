import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {graphQLClient}  from '../../client';
import {USERS} from '../../queries';
import {LOAD_USERS} from '../../store/actions';
import Loader from '../Element/Loader'
import Handimans from './handimans';

function Jobsection(){
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const func = async() => {
		try {
			
			const data = await graphQLClient.request(USERS)
			// console.log(data.users)
		dispatch(dispatch(LOAD_USERS(data.users)));
		setLoading(false)
	
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 

	useEffect(()=>{
	func()
	},[])

	return(
		<div className="section-full bg-white content-inner-2">
			<div className="container">
				<div className="d-flex job-title-bx section-head">
					<div className="mr-auto">
						<h2 className="m-b5">Top Rated Handimans</h2>
						<h6 className="fw4 m-b0">20+ Recently Added</h6>
					</div>
					<div className="align-self-end">
						<Link to={"/handimans"} className="site-button button-sm">Browse All <i className="fa fa-long-arrow-right"></i></Link>
					</div>
				</div>
				{
						loading?
						<div >
							<Loader />
							</div>
						:
						<div className="row">
						
				<div className="col-xl-9 col-lg-8">
				<Handimans itemsPerPage={4} />
								</div>
					<div className="col-lg-3">
						<div className="sticky-top">
							<div className="candidates-are-sys m-b30">
								<div className="candidates-bx">
									<div className="testimonial-pic radius"><img src={require("./../../images/testimonials/alex-starnes-WYE2UhXsU1Y-unsplash.jpg")} alt=""  style={{width: '100%', height:'100%'}}/></div>
									<div className="testimonial-text">
										<p>I get my regular job request via handiman executive! I used the site all the time.</p>
									</div>
									<div className="testimonial-detail"> <strong className="testimonial-name">Constants Ikechukwu</strong> <span className="testimonial-position">Port Harcourt, Rivers</span> </div>
								</div>
							</div>
							<div className="quote-bx">
								<div className="quote-info">
									<h4>Expose your skills to the world around you!</h4>
									<p>The world should know what you can do.</p>
									<Link to={"/register"} className="site-button">Create an Account</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
						
					}
			
			</div>
		</div>
	)
}
export default Jobsection;