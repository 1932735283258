import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { usePaystackPayment } from 'react-paystack';
import {Link, useHistory} from 'react-router-dom';
import Header from './../Layout/userHeader';
import Footer from './../Layout/Footer';
import Profilesidebar from './../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {userDetail, SAVE_PAYMENT} from '../../queries';
import {LOAD_USER_DETAILS,USER_PAYMENT_STATUS } from '../../store/actions'
import swal from 'sweetalert';
import Loader from '../Element/Loader';

function Jobpayment(){
	const history = useHistory()
	const userDetails = useSelector((state)=> state.user)
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const func = async() => {
		try {
			const data = await graphQLClient.request(userDetail)
			console.log(data.user)
			const {id, 
				isverified,
				// username,
				 name, 
				 email, 
				 phone, 
				 city,
				 state,
				  pic,
				  title,
				  language,
				  age,
				salary,
				expected,
				description,
				country,
				postalcode,
				address,
				projects,
				expriences,
				skills,
				uploads,
				proves,
                payment_status,
				unread_chats
			} = data.user
			dispatch(LOAD_USER_DETAILS({
				id: id,
				isverified: isverified,
				name: name,
				email: email,
				phone: phone,
				country: country,
				image: pic,
				title: title,
				language: language,
				age: age,
				salary: salary,
				expected: expected,
				description: description,
				postalcode: postalcode,
				city: city,
				state: state,
				address: address,
				exprience: expriences,
				projects: projects,
				skills: skills,
				portfolio: uploads,
				prove: proves,
                payment_status: payment_status,
				unread_chats: unread_chats?.length,

			}))
			setLoading(false)
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 
// Payment Details
const config = {
    reference: (new Date()).getTime().toString(),
    email: "samueldan@live.com",
    amount: 2010 * 100,
    publicKey: 'pk_test_0a3aaced9a1b53974f920b3ce89aef6de8c93c49',
};
const initializePayment = usePaystackPayment(config);
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    savePayment("Paystack",reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    swal({
        title: "Warning!",
        text: "You need to make payment for your account to be verified",
        icon: "warning"
      })
  }
const savePayment = async(channel,txnData) =>{

console.log(userDetails)
try {
	const variables = {
		amount: 2010,
		channel: channel,
		txnId: txnData.reference,

	}
	const data = await graphQLClient.request(SAVE_PAYMENT,variables)
	console.log(data)
	if(data){
       dispatch(USER_PAYMENT_STATUS(true))
		swal({
			title: "Success Message",
			text: "payment has been completed sucessfully",
			icon: "success",
		  })
		  return history.push('/profile')
	}

	
} catch (error) {
	console.log(error?.response?.errors[0]?.message)
    swal({
        title: "Error Message",
        text: "An Error has occured with your payment Verification",
        icon: "error",
        dangerMode: true
      })
}

}
	useEffect(()=>{
	func()
	},[])
	return(
		<>	
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
				{
							loading?
							<div >
								<Loader />
								</div>
							:
	<>
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar /> 
								<div className="col-xl-9 col-lg-8 m-b30">
									<div className="job-bx job-profile">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Account Activation</h5>
											<Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link>
										</div>
										{/* <form> */}
											<div className="row m-b30">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Your Name:</label> 
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<input type="text" className="form-control" placeholder="Name" defaultValue={userDetails.name} /> 
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Professional Title:</label>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<input type="text" className="form-control" placeholder="Title" defaultValue={userDetails.title} />
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Role</label>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<input type="text" className="form-control" placeholder="Role" defaultValue={"Handiman"} />
													</div>
												</div>
                                                <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                <label>Amount: <span style={{color:'grey'}}>₦2000</span></label>
                                                </div>
                                                <div className="form-group">
                                                <label>vat(5%): <span style={{color:'grey'}}>₦10</span></label>
                                                </div>
                                                <div className="form-group">
                                                <label>Total:  <span style={{color:'grey'}}>₦2010</span></label>
                                                </div>
												</div>
											</div>

											<button className="site-button m-b30"  
                                            onClick={() => {
                                         initializePayment(onSuccess, onClose)
                                }}>Pay With Paystack</button>
										{/* </form> */}
									</div>    
								</div>
							</div>
						</div>
					</div>
					</>						
}
				</div>
			</div>
			<Footer />	
		</>
	)
} 
export default Jobpayment;