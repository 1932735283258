import { GraphQLClient} from 'graphql-request'
import { io } from "socket.io-client";

// var HOST = location.origin.replace(/^http/, 'https')
     const endpoint = 'https://psggraphql.herokuapp.com/graphql'
    //const endpoint = 'http://localhost:5000/graphql'
   export const graphQLClient = new GraphQLClient(endpoint, {
      credentials: 'include',
      // mode: 'cors',
    });
     const socketEndpoint = 'https://psggraphql.herokuapp.com'
    //  const socketEndpoint = 'http://localhost:5000'
     export const socketClient = io(socketEndpoint);

  