import React,{useEffect} from 'react';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Markup from './markup/Markup'
import { socketClient } from './client';
import { useCustomer } from './authenicationProvider';
import {useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import {OTHER_MSG, MSG_COUNT} from './store/actions'
import { useLocation } from 'react-router-dom';
function App (props) {
const {username} = useCustomer()
const dispatch = useDispatch()
const location = useLocation()
console.log(location)
	useEffect(()=>{

       // Join user to Socket

	   if(username){
		socketClient.emit('joined', username);
		console.log(username)
		// So we dont get notify on the same message
	if(location.pathname !=="/in-app-chat"){
		 // Listen Chat message general
	// 	 socketClient.on('chat message', function(msg) {
	// 		console.log("from app"+msg)
    //         toast.info(`A new message from ${msg.sender}`)
    //         dispatch(OTHER_MSG(msg))
	// 		dispatch(MSG_COUNT())
      
    //   });
	}




	  }
	        

	},[])
		return (
			<>
 <Markup />
 <ToastContainer />
         </>
		);
};

;


export default App;
