import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
// import '../../css/chat/normalize.css'
import '../../css/chat/style.css'
import { useSelector, useDispatch } from 'react-redux';
import { userDetail, chats } from '../../queries';
import { SEND_MSG, LOAD_MSG, LOAD_USER_DETAILS, MSG_COUNT, OTHER_MSG, UPDATE_MSG_URL, MSG_COUNT_SUB, READ_ALL, READ_ONE } from '../../store/actions'
import { graphQLClient } from '../../client';
import { socketClient } from '../../client';
import { useMutation, gql } from "@apollo/client"
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { useStickyScroll } from 'use-chat-scroll'
import { FormateDateTime, FormatMonth, FormatDay, FormatYear } from 'react-date-time-formatter'
import Uniqid from 'uniqid';
import fileDownload from 'js-file-download'
import axios from 'axios'
import Background from '../../images/background/8c98994518b575bfd8c949e91d20548b.jpg';
const Upload = gql`
 mutation profileUpload($file: Upload! ) {
	uploadFiles(file: $file) {
    id
   url
  }
}
`;
const HandimanInAppChat = (props) => {
    const fileRef = useRef();
    // console.log(props.location.state?.user)
    const recipient = props.location.state?.user;
    const [temImg, setTemImg] = useState(null)
    const [temImgPick, setTemImgPick] = useState(false)
    const [temImgf, setTemImgf] = useState(null)
    const [temImgV, setTemImgV] = useState(null)
    const [typing, setTyping] = useState(null)
    const [online, setOline] = useState(recipient.online)
    const [loading, setLoading] = useState(true)
    const [msg, setMsg] = useState('')
    const store = useSelector((state) => state)
    const user = useSelector((state) => state.user)
    const re = store.chat.curRecipient
    const dispatch = useDispatch()
    // Third party scroll
    const containerRef = useRef()
    useStickyScroll(containerRef, store.chat.msg);

    const func = async () => {
        try {
            const data = await graphQLClient.request(userDetail)
            const {
                id,
                isverified,
                username,
                name,
                email,
                phone,
                city,
                state,
                pic,
                title,
                language,
                age,
                salary,
                expected,
                description,
                country,
                postalcode,
                address,
                projects,
                expriences,
                skills,
                uploads,
                proves,
                payment_status,
                unread_chats
            } = data.user
            dispatch(LOAD_USER_DETAILS({
                id: id,
                isverified: isverified,
                name: name,
                username: username,
                email: email,
                phone: phone,
                country: country,
                image: pic,
                title: title,
                language: language,
                age: age,
                salary: salary,
                expected: expected,
                description: description,
                postalcode: postalcode,
                city: city,
                state: state,
                address: address,
                exprience: expriences,
                projects: projects,
                skills: skills,
                portfolio: uploads,
                prove: proves,
                payment_status: payment_status,
                unread_chats: unread_chats?.length,
                unread_chats_data: unread_chats

            }))
            setLoading(false)
            await graphQLClient.request(chats, { user: recipient?.username }).then((data) => {
                console.log(data.chat)
                dispatch(LOAD_MSG(data.chat))
                //  Declear all message to be read where current user is sender and message was sent by reciever
                if (data.chat?.length !== 0) {
                    // console.log(data.chat[0].unread.length)
                    socketClient.emit('Read all', {
                        sender_id: user.username,
                        sender: user.name,
                        reciever_id: recipient?.username,
                        reciever_name: recipient?.name,
                        unread: unread_chats,
                        unread_count: unread_chats.length
                    });
                    dispatch(MSG_COUNT_SUB(unread_chats.length))
                }

            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error?.response?.errors[0]?.message)
            setLoading(false)
        }

    }
    // Send message function
    const message_id = Uniqid('idst-');
    const sendFunc = () => {

        if (temImgf) {
            setTemImgPick(false)
            temImgV.valid && uploadFile({ variables: { file: temImgf } })

            dispatch(SEND_MSG({
                message_id: message_id,
                sender_id: user.username,
                sender: user.name,
                reciever_id: recipient?.username,
                reciever_name: recipient?.name,
                type: "img",
                file: {
                    url: temImg,
                },
                message: msg,
                createdAt: new Date(),
                senderDetails: user,
                status: 'false'
            }))
        } else {

            socketClient.emit('chat message', {
                message_id: message_id,
                sender_id: user.username,
                sender: user.name,
                reciever_id: recipient?.username,
                reciever_name: recipient?.name,
                type: "text",
                message: msg,
                createdAt: new Date(),
                senderDetails: user,
                status: 'false'
            });
            dispatch(SEND_MSG({
                message_id: message_id,
                sender_id: user.username,
                sender: user.name,
                reciever_id: recipient?.username,
                reciever_name: recipient?.name,
                type: "text",
                message: msg,
                createdAt: new Date(),
                status: 'false'
            }))
        }

        setMsg('')
        // console.log(user.username)
    }
    const [uploadFile, { loadingFile, error }] = useMutation(Upload, {
        onCompleted: (data) => {
            console.log(data.uploadFiles)

            socketClient.emit('chat message', {
                message_id: message_id,
                sender_id: user.username,
                sender: user.name,
                reciever_id: recipient?.username,
                reciever_name: recipient?.name,
                type: "img",
                message: msg,
                file: {
                    id: data.uploadFiles.id,
                    url: data.uploadFiles.url,
                },
                createdAt: new Date(),
                status: 'false'
            });
            setTemImgf(null);
            setTemImgPick(null);
            console.log(fileRef.current.value)
            fileRef.current.value = '';
            console.log(fileRef.current.value)
            dispatch(UPDATE_MSG_URL({ tem: temImg, saved: data.uploadFiles.url }))
        },
        onError: (error) => console.log(error)
    })
    // Set temporal image

    // Upload chat img
    var uploadImageToStorage = async (input) => {
        const {
            target: {
                validity,
                files: [file]
            }
        } = input
        if (!(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.name)) {
            console.log(file?.type)
            toast.warning(`Only image file are allowed`)
            return null
        }
      console.log(input.target.value)
        setTemImgf(file)
        setTemImgV(validity)
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = function () {
            setTemImg(reader.result)
            setTemImgPick(true)
            // console.log(reader.result)
        };
        input.target.value = null


    }
    //  AM/PM formatter
    function formatAMPM(string) {
        var date = new Date(string)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    //   Image download function
    const handleDownload = async (uri) => {
        let filename = Math.floor(Math.random() * 10000)
        try {
            await axios.get(uri, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, `${filename}.png`)
                })
        } catch (error) {
            console.log(error)
        }


    }

    useEffect(() => {
        // dispatch(SET_RECIPIENT(recipient))

        // const messageDiv = document.getElementById("msg-ui")
        func()
        // console.log(store.chat.curRecipient)
        // Listen Online Status
        socketClient.on('online status', function (user) {

            //    console.log(user)
            if (user == recipient?.username) {
                setOline(true)
                toast.info(`${recipient?.name} is Online`)
            }

        });
        // Listen Offline Status
        socketClient.on('offline status', function (user) {
            // console.log(user)
            if (user == recipient?.username) {
                setOline(null)
            }

        });

        // Listen Chat message general
        //  socketClient.on('chat message', function(msg) {
        //     console.log(msg.sender_id)
        //     if(recipient?.username !== msg.sender_id){
        //         toast.info(`A new message from ${msg.sender}`)
        //         dispatch(OTHER_MSG(msg))
        //         dispatch(MSG_COUNT())
        //     }

        //   });
        // Listen all Read message general
        socketClient.on(`Read all ${user.username}`, function (data) {
            dispatch(READ_ALL(data.unread))
            console.log(data.unread)
        });

        //  Only listen to read message from this sender only
        socketClient.on(`Read Message ${recipient?.username}`, function (msg) {
            dispatch(READ_ONE(msg))
            // console.log(msg)
        });
        //  Only listen to message from this sender only
        socketClient.on(`chat ${recipient?.username}`, function (msg) {
            dispatch(SEND_MSG(msg))
            // Read message on the sender end
            socketClient.emit("Read Message", msg)
            // console.log(msg)
        });

        //  Only listen to typing from this sender only
        socketClient.on(`typing ${recipient?.username}`, function (data) {
            //   console.log(data)
            setTyping(true)
        });
        //  Only listen to typing from this sender only
        socketClient.on(`stop typing ${recipient?.username}`, function (data) {
            // console.log(data)
            setTyping(null)
        });

    }, [])

    // Redirect function
    if (!recipient) {
        return <Redirect to={{
            pathname: "/handimans",
            state: { previousLocation: '/in-app-chat' }
        }} />
    }
    return (
        <>
            <div className="ui">
                <div className="chat">
                    <div className="top">
                        <div className="avatar">
                            <img width="50" height="50" src={
                                recipient?.previousLocation !== '/chats' ?
                                    recipient?.image ? recipient?.image : require('../../images/logo/icon2.png')
                                    :
                                    recipient?.pic ? recipient?.pic : require('../../images/logo/icon2.png')
                            } />
                        </div>
                        <div className="info">
                            <div className="name">{recipient?.name}</div>
                            <div className="count">
                                {
                                    online ?
                                        typing ?
                                            "Typing"
                                            :
                                            "Online"
                                        :
                                        "Offline"
                                }

                            </div>
                        </div>
                        <i className="fa fa-star"></i>
                    </div>
                    <div className='msg-ui' id="msg-ui" ref={containerRef} style={{
                        backgroundImage: "url(" + Background + ")",
                        backgroundSize: 'cover',
                        position: 'relative'
                    }}>
                        {/* Loading spinner for the message */}
                        {
                            loading ?
                                <Spinner animation="grow" variant="info" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} />
                                :

                                store.chat.msg.map((item, index) => {
                                    if (item.sender_id == user.username) {
                                        return (
                                            <li className="i" key={index}>
                                                <div className="head">
                                                    <FormateDateTime
                                                        show={false}
                                                        resultIsIn={(props) => {
                                                            // console.log("result",props)
                                                            const conIsIn = props.toLowerCase()
                                                            if (conIsIn === 'month') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}> {item.createdAt} </FormatDay>  <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else if (conIsIn === 'year') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatMonth > {item.createdAt} </FormatMonth>  <FormatYear >{item.createdAt} </FormatYear></span>)
                                                            }
                                                            else if (conIsIn === 'day') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else if (conIsIn === 'week') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, Today</span>)
                                                            }

                                                        }}
                                                    >
                                                        {item.createdAt}
                                                    </FormateDateTime>
                                                    {/* <span className="time">10:14 AM, Today</span> */}
                                                    <span className="name">{user.name}</span>
                                                </div>

                                                <div className="message">
                                                    {
                                                        item.type !== "text" ?
                                                            <div
                                                            //  className=' float-right'
                                                            >
                                                                <img src={item.file?.url} className='img-thumbnail' style={{ maxWith: "80px", maxHeight: "80px", }} />

                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <p style={{ marginBottom: 0 }}> {item.message} </p>
                                                    {
                                                        item.status == 'false' ?
                                                            <div className='float-right'>
                                                                <i className='fa fa-check' style={{ verticalAlign: 0, fontSize: '15px' }}></i>
                                                            </div>
                                                            :
                                                            <div className='float-right'>
                                                                <i className='fa fa-check' style={{ verticalAlign: 0, color: '#FFF', fontSize: '15px' }}></i>
                                                                <i className='fa fa-check' style={{ verticalAlign: 0, color: '#FFF', fontSize: '15px' }}></i>
                                                            </div>

                                                    }
                                                </div>

                                            </li>
                                        )
                                    } else {

                                        return (
                                            <li className="friend-with-a-SVAGina">
                                                <div className="head">
                                                    <span className="name">{recipient?.name}</span>
                                                    {/* <span className="time">10:15 AM, Today</span> */}
                                                    <FormateDateTime
                                                        show={false}
                                                        resultIsIn={(props) => {
                                                            // console.log("result",props)
                                                            const conIsIn = props.toLowerCase()
                                                            if (conIsIn === 'month') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}> {item.createdAt} </FormatDay>  <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else if (conIsIn === 'year') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatMonth > {item.createdAt} </FormatMonth>  <FormatYear >{item.createdAt} </FormatYear></span>)
                                                            }
                                                            else if (conIsIn === 'day') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else if (conIsIn === 'week') {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, <FormatDay offset={true}>{item.createdAt} </FormatDay> <FormatMonth > {item.createdAt} </FormatMonth></span>)
                                                            } else {
                                                                return (<span className="time">{formatAMPM(item.createdAt)}, Today</span>)
                                                            }

                                                        }}
                                                    >
                                                        {item.createdAt}
                                                    </FormateDateTime>
                                                </div>

                                                <div className="message">
                                                    {
                                                        item.type !== "text" ?
                                                            <div
                                                                // className='col-sm-3 col-md-3 col-xl-3'
                                                                style={{ position: 'relative', width: '120px', height: '80px' }}
                                                            >

                                                                <img src={item.file?.url} className='img-thumbnail' style={{ width: "100%", height: "100%", }} />

                                                                <a
                                                                    href={item.file?.url}
                                                                    download
                                                                    style={{ position: 'absolute', bottom: 0, right: 0, width: '25px', height: '25px', borderRadius: '50%' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleDownload(item.file?.url)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-download" style={{ color: '#62bf6e' }} />
                                                                </a>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {item.message}
                                                </div>
                                            </li>)
                                    }
                                })
                        }
                    </div>
                    <div className="write-form">
                        {
                            temImgPick ?
                                <div className='preview'>
                                    <img src={temImg} className='img-thumbnail' />
                                    <p>{temImgf.name}</p>

                                </div>
                                :
                                null
                        }


                        <textarea placeholder="Type your message" name="e" id="texxt" rows="2" value={msg} onChange={(e) => setMsg(e.target.value)} onFocus={() => socketClient.emit("typing", { reciver: recipient?.username, sender: user.username })} onBlur={() => socketClient.emit("stop typing", { reciver: recipient?.username, sender: user.username })}></textarea>
                        <i className="fa fa-picture-o" onClick={() => fileRef.current.click()}>
                            <input
                            type="file"
                            id="upload_image"
                            accept="image/*"
                            onChange={(e) => uploadImageToStorage(e)}
                            ref={fileRef}
                            multiple={false}
                            hidden
                        /> </i>
                        <span className="send" onClick={() => sendFunc()}>Send</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HandimanInAppChat;