import React from 'react';
import {Link} from 'react-router-dom';

function Jobcategories(){
	return(
		<div className="row sp20" id="categories">
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-wrench"></i></div>
						<Link to={"/company-manage-job"} className="dez-tilte">Generator Mechanic</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-location-pin"></i></div> 
					</div>
				</div>				
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-plug"></i></div>
						<Link to={"#"} className="dez-tilte">Electrician</Link>
						{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-wand"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-car"></i></div>
						<Link to={"#"} className="dez-tilte">Auto Mechanic</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-wallet"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-lightbulb-o"></i></div>
						<Link to={"#"} className="dez-tilte">Solar Technicians</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-cloud-up"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-bath"></i></div>
						<Link to={"#"} className="dez-tilte">Plumbering</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-bar-chart"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-scissors"></i></div>
						<Link to={"#"} className="dez-tilte">Tailoring</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-tablet"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="fa fa-cutlery"></i></div>
						<Link to={"#"} className="dez-tilte">Catering</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-camera"></i></div> 
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="icon-bx-wraper">
					<div className="icon-content">
						<div className="icon-md text-primary m-b20"><i className="ti-camera"></i></div>
						<Link to={"#"} className="dez-tilte">Photography</Link>
					{/* <p className="m-a0">198 Handimans</p> */}
						<div className="rotate-icon"><i className="ti-panel"></i></div> 
					</div>
				</div>
			</div>
			{/* <div className="col-lg-12 text-center m-t30">
				<button className="site-button radius-xl">All Categories</button>
			</div> */}
		</div>
	)
}

export default Jobcategories;