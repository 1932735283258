import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {graphQLClient}  from '../../client';
import {RecoverPassword} from '../../queries'
import { toast } from 'react-toastify';
import {Spinner} from 'react-bootstrap'
import bnr from "./../../images/background/pexels-field-engineer-442151.jpg";


function PasswordRecovery(props) {
	const [email, setEmail] = useState('');
    let errorsObj = { email: '' };
    const [errors, setErrors] = useState(errorsObj);
	const [processing, setProcessing] = useState(false);
	const [completed, setCompleted] = useState(false)

    async function onReset(e) {
        e.preventDefault();
		setProcessing(true)
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }

        setErrors(errorObj);
		if (error) {
			setProcessing(false)
			return ;
		}
       
		try {
				const variables = {
				 email: email, 
			  }
	
			 await graphQLClient.request(RecoverPassword, variables).then((data)=>{
				console.log(data)
				setProcessing(false)
				setCompleted(true)
				if(data){
					toast.success(`An reset email has been sent to your email address`)
				}
			}).catch((error)=> {
				setProcessing(false)
				toast.error(`An error has occured, please try again`)
			}
			)
		
		} catch (error) {
			console.log(error)
			setProcessing(false)
			// swal({
			// 	title: "Error Message",
			// 	text: error.response.errors[0].message,
			// 	icon: "warning",
			// 	dangerMode: true,
			//   })
		}

		
    }
	return(
		<div className="page-wraper">
			<div className="browse-job login-style3">
				
				<div className="bg-img-fix" style={{backgroundImage:`url(${bnr})`, height: "100vh" }}>
					<div className="row">
						<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
							<div className="login-form style-2">
								<div className="logo-header text-center p-tb30">
									<Link to={"./"}><img src={require("./../../images/custom-logo3.png")} alt=""  /></Link>
								</div>
								<div className="clearfix"></div>
								<div className="tab-content nav p-b30 tab">
									<div id="login" className="tab-pane active ">
										{props.errorMessage && (
											<div className=''>
												{props.errorMessage}
											</div>
										)}
										{props.successMessage && (
											<div className=''>
												{props.successMessage}
											</div>
										)}
										<form className=" dez-form p-b30" onSubmit={onReset}>
											<h3 className="form-title m-t0">Password Recovery</h3>
											<div className="dez-separator-outer m-b5">
												<div className="dez-separator bg-primary style-liner"></div>
											</div>
											{
												completed?
								<div className="alert alert-success" role="alert">
										Please check your email to reset your password
										</div>
										:
										<>
										<p>Enter your e-mail address. </p>
										<div className="form-group">
											<input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
										 <div className="text-danger">{errors.email && <div>{errors.email}</div>}</div>
										</div>
										<div className="text-center bottom">
										<button type="submit" className="site-button button-md btn-block text-white"  disabled={processing}>
												{
													processing?
										<Spinner animation="border" />
										:
										'Reset' 
												}
											
												
												</button>
										</div>
										</>
											}
											
										

										</form>
									</div>
								</div>	
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	)
}

export default PasswordRecovery;