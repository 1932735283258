import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import PageTitle from './../Layout/PageTitle';
import {useSelector,useDispatch} from 'react-redux';
import {graphQLClient}  from '../../client';
import {getuser,RATE_USER} from '../../queries';
import {LOAD_SINGLE_USER} from '../../store/actions';
import {FormateDateTime} from 'react-date-time-formatter';
import Loader from '../Element/Loader';
import {Modal} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import swal from 'sweetalert';
import {useCustomer} from '../../authenicationProvider'

var bnr = require('./../../images/background/pexels-cottonbro-5089178.jpg');

const blogGrid =[
	{
		image:require('./../../images/blog/grid/pic1.jpg'),
	},
	{
		image:require('./../../images/blog/grid/pic2.jpg'),
	},
	{
		image:require('./../../images/blog/grid/pic3.jpg'),
	},
	{
		image:require('./../../images/blog/grid/pic4.jpg'),
	},
]

function Jobdetail(props){
	const {customer} = useCustomer()
const [projects, setProjects] = useState(false);
	const user = useSelector((state)=> state.web)
	const {userid} = props.location.state;
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const [rating, setRating] = useState({rating: '', review: ''})
	const func = async() => {
		try {
			const data = await graphQLClient.request(getuser,{id: userid});
			console.log(data)
		const {id, 
			username,
			 name, 
			 email, 
			 phone, 
			 city,
			  pic,
			  title,
			  language,
			  age,
			salary,
			expected,
			description,
			country,
			postalcode,
			address,
			projects,
			expriences,
			skills,
			uploads,
			online,
			createdAt
		} = data.getUser
		// console.log(createdAt)
		dispatch(LOAD_SINGLE_USER({
			id: id,
			username: username,
			name: name,
			email: email,
			phone: phone,
			country: country,
			image: pic,
			title: title,
			language: language,
			age: age,
			salary: salary,
			expected: expected,
			description: description,
			postalcode: postalcode,
			city: city,
			address: address,
			exprience: expriences,
			projects: projects,
			skills: skills,
			portfolio: uploads,
			online: online,
			joined: createdAt

		}))
		setLoading(false)
		// console.log(user)
	
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 
// eslint-disable-next-line
	useEffect(()=>{
	func()
	},[])
	const takeRating = async() =>{
		try {
			console.log(rating)
			let variables ={
				rating: rating.rating,
				review: rating.review,
				user: userid,
			}
			let data = await graphQLClient.request(RATE_USER,variables).catch((e)=> console.log(e))
			setProjects(false);
			console.log(data)
		} catch (error) {
			if(error){
				return 	swal({
					title: "Error Message",
					text: "Only Employer can rate a handiman, login and try again",
					icon: "error",
				  })
			}
		
		}
		
	}
	return(
		<>
			<Header />	
			<div className="page-content bg-white">
				<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + bnr + ")"}}>
					<PageTitle activeName="Handiman Detail" motherName="Home" />
				</div>
				<div className="content-block">
{
							loading?
							<div >
								<Loader />
								</div>
							:
	<>
						<div className="section-full content-inner-1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4">
									<div className="sticky-top">
										<div className="row">
											<div className="col-lg-12 col-md-6">
												<div className="m-b30">
													<img src={user?.image? user.image : require("./../../images/blog/grid/pic1.jpg")} alt="" />
												</div>
											</div>
											<div className="col-lg-12 col-md-6">
												<div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
													<h4 className="text-black font-weight-700 p-t10 m-b15">Handiman Details</h4>
													<ul>
														<li><i className="ti-location-pin"></i><strong className="font-weight-700 text-black">Address</strong><span className="text-black-light"> {user.address} </span></li>
														<li><i className="ti-envelope"></i><strong className="font-weight-700 text-black">Email</strong> {user.email}</li>
														<li><i className="ti-mobile"></i><strong className="font-weight-700 text-black">Phone</strong>{user.phone}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="job-info-box">
										<h3 className="m-t0 m-b10 font-weight-700 title-head">
											<Link to={"#"} className="text-secondry m-r30">{user.name}</Link>
										</h3>
										<ul className="job-info">
											<li>{user.title}</li>
											{/* <li><strong>Joined:</strong> <FormateDateTime>{user.joined}</FormateDateTime> </li> */}
											<li><i className="ti-location-pin text-black m-r5"></i>{user.city}, {user.country} </li>
										</ul>
										<p className="p-t20">{user.description}</p>
										<h5 className="font-weight-600">Exprience</h5>
										{/* <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div> */}
										<div id="education_bx" className="job-bx bg-white m-b30">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="clearfix m-b20">
												{
											user.exprience.map((item,index)=> (
											<div key={index}>
										<h6 className="font-14 m-b0">{item.designation}</h6>
										<p className="m-b0">{item.organization}</p>
										<p className="m-b0">{item.smonth} {item.syear}  to {item.emonth} {item.eyear}</p>
										<p className="m-b0">{item.des}</p>
											</div>	
											))
										}
												</div>
											</div>
										</div>
									</div>
										<h5 className="font-weight-600">Key Skills</h5>
										<div id="key_skills_bx" className="job-bx bg-white m-b30">
										<div className="job-time mr-auto">
										{
												user.skills.map((item, index)=>(
													<>
													<Link to={"#"} className="mr-1" key={index} onClick={(e)=> e.preventDefault(e)}><span>{item.skills}</span></Link>
													</>
												))
											}
										</div>
										

										
									</div>
									<h5 className="font-weight-600">Projects</h5>
										{/* <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div> */}
										<div id="projects_bx" className="job-bx bg-white m-b30">
										{
											user.projects.map((item, index)=> (
												<div key={index}>
										<h6 className="font-14 m-b0">{item.title}</h6>
										<p className="m-b0">{item.client}</p>
										<p className="m-b0">{item.smonth} {item.syear}  to {item.emonth} {item.eyear}</p>
										<p className="m-b0">{item.des}</p>
										<strong> {item.status}</strong>
												</div>
											))
										}
										
									</div>
									<h5 className="font-weight-600">Portfolio</h5>
										<ul className="dez-gallery-listing gallery-grid-4 gallery mfp-gallery sp10 row">
						{user.portfolio.map((item,index)=>(
							<li className="card-container col-lg-4 col-md-3 col-sm-6 col-6 advertising design photography" key={index}>
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect ">
									<Link to ={"#"}> <img src={item.url}  alt="" /> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<a className="mfp-link" title="Image Title Come Here" href="images/gallery/masonry/img6.jpg"> <i className="ti-fullscreen"></i> </a>	
											</div>
										</div>
									</div>
								</div>
							</li>
						))}
						
					</ul>
					<Link className="site-button" to={{
									 pathname:"/in-app-chat",
									//  search:'?sort=name',
									//  hash:'#the-hash',
									 state: {previousLocation: '/job-detail', userid: user.id, user: user}
								 }}><i className='fa fa-paper-plane'></i> Send Message</Link>
										{/* <a href={`mailto:${user.email}`} className="site-button">Hire Handiman</a> */}
							{/* {
								customer !=='' && customer !== 'handiman' ?
								<button className="site-button" style={{marginLeft: 5}} onClick={(e)=> {
									e.preventDefault();
									setProjects(true)
								}}>Rate Handiman</button>
								:
								null
							} */}
									

									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section-full content-inner">
						<div className="container">
							<div className="row">
								{blogGrid.map((item,index)=>(
									<div className="col-xl-3 col-lg-6 col-md-6" key={index}>
										<div className="m-b30 blog-grid">
											<div className="dez-post-media dez-img-effect "> <Link to={"/blog-details"}><img src={item.image} alt="" /></Link> </div>
											<div className="dez-info p-a20 border-1">
												<div className="dez-post-title ">
													<h5 className="post-title"><Link to={"/blog-details"}>Title of blog post</Link></h5>
												</div>
												<div className="dez-post-meta ">
													<ul>
														<li className="post-date"> <i className="ti-location-pin"></i> London </li>
														<li className="post-author"><i className="ti-user"></i>By <Link to={"#"}>Jone</Link> </li>
													</ul>
												</div>
												<div className="dez-post-text">
													 <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
												</div>
											    <div className="dez-post-readmore"> 
													<Link to={"/blog-details"} title="READ MORE" rel="bookmark" className="site-button-link"><span className="fw6">READ MORE</span></Link>
												</div>
											</div>
										</div>
									</div>
								))}	
							</div>
						</div>
					</div>
	</>						
}

				</div>
			</div>		
			<Footer />
{/* Rating Model */}
<Modal className="modal fade modal-bx-info editor" show={projects} onHide={setProjects}> 
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" id="ProjectsModalLongTitle">Rate Handiman Performance</h5>
														<button type="button" className="close" onClick={()=>setProjects(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Rating</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select" value={rating.rating} onChange={(e)=> setRating({...rating, rating: e.target.value}) }>
																					<option value={5}>Excellent</option>
																					<option value={4}>Good</option>
																					<option value={3}>Fairly Good</option>
																					<option value={2}>Fair</option>
																					<option value={1}>Poor</option>
																				</Form.Control>
																			</div>
																			
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Details of Project</label>
																		<textarea className="form-control" placeholder="Type Description" value={rating.review} onChange={(e)=> setRating({...rating, review: e.target.value}) }></textarea>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setProjects(false)}>Cancel</button>
														<button type="button" className="site-button" onClick={(e)=> takeRating(e)}>Save</button>
													</div>
												</div>
											</div>
										</Modal>
		</>		
	)
}
export default Jobdetail;