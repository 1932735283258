import React,{useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux'
import {useCustomer} from '../../authenicationProvider'
import {graphQLClient}  from '../../client';
import {userpic, LOGOUT} from '../../queries';
import { UPLOAD_IMG } from '../../store/actions'
import '../../css/menu.css'
function LogoutPage(props){
	const history =  useHistory()
    const userDetails = useSelector((state)=> state.user)
    const {customer, setCustomer} = useCustomer()
    const dispatch = useDispatch()
	const func = async() => {
        if(customer){
		try {
			const data = await graphQLClient.request(userpic)
			console.log(data.user.pic)
			const {
				  pic,
			} = data.user
			dispatch(UPLOAD_IMG(pic))
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
		}
	} 
}
    // console.log(userDetails)
	function menuToggle() {
		const toggleMenu = document.querySelector(".menu");
		toggleMenu.classList.toggle("active");
	  }
	  var signOut = async (e) =>{
		e.preventDefault()
		try {
			const data = await graphQLClient.request(LOGOUT)
			console.log(data)
			setCustomer(null)
			if(data){
				return  history.push("./" )
			}
		} catch (error) {
			console.log(error.response.errors[0].message)
		}
	}
    useEffect(()=>{
        func()
        },[])
    return(
        <>
             <div className="action">
      <div className="profile" onClick={()=> menuToggle()}>
        {/* <img src="./assets/avatar.jpg" /> */}
		<img className='img-responsive' src={userDetails.image !=='' && userDetails.image !==null? userDetails.image : require('../../images/logo/icon2.png')} alt="profile picture"  />	
      </div>
      <div className="menu">
        <h3>{userDetails.name}<br /><span>{userDetails.title}</span></h3>
        <ul>
          <li>
            <i className='fa fa-user-o'></i><Link to={"/profile"}>Profile</Link>
          </li>
          <li>
		  <i className='ti-comments'></i><Link to={"/chats"}>Inbox</Link>
          </li>
		  {
							customer !=="Employer" && customer !==null?
							<li>
							<i className='fa fa-file-text-o'></i><Link to={"/my-resume"}>My Resume</Link>
							</li>
							:
							null
		  }

          <li><i className='fa fa-key'></i><Link to={"/change-password"}>Change Password</Link></li>
          <li>
		  <i className='fa fa-sign-out'></i><a href="#" onClick={(e)=> signOut(e)}>Logout</a>
          </li>
        </ul>
      </div>
    </div>	
			{/* <Link to ={'/profile'}   >
			<img className='img-responsive' style={{width: '40px', height: '40px', borderRadius: '50%'}} src={userDetails.image !=='' && userDetails.image !==null? userDetails.image : require('../../images/logo/icon2.png')} alt="profile picture" 
            // onClick={()=> props.showProfile()}
             />		
			</Link> */}
        </>
    )
} 


export default LogoutPage;