import React from 'react'
import '../../css/loader.css'

const Loader =() => {
    return(
<>
<div className="loadingio-spinner-pulse-xys3s4ogjj"><div className="ldio-1r1g1o9j93t">
<div></div><div></div><div></div>
</div></div>
</>
    )
}

export default Loader;