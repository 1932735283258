import React from "react";
import { Redirect, Route } from "react-router-dom";
import {useCustomer} from './authenicationProvider'

function ProtectedRoute({ component: Component, ...restOfProps }) {
	const {customer} = useCustomer()
  console.log("this", customer);
if(customer !=="handiman" && customer !=="Employer"){
  return <Redirect to="/login" />
}
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        customer ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;