import React from 'react'
import '../../css/preload.css'

const Spinner =() => {
    return(
<>
<div className="loadingio-spinner-spinner-de5581p520d"><div className="ldio-jb1d02pyik">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
</>
    )
}

export default Spinner;