import React,{useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Header from './../Layout/userHeader'; 
import Footer from './../Layout/Footer'; 
import Profilesidebar from './../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {VerifyEmail,SEND_TOKEN, userDetail} from '../../queries'
import {EDIT_USER_STATUS,LOAD_USER_DETAILS } from '../../store/actions'
import {useDispatch} from 'react-redux'
import swal from 'sweetalert';
import Loader from '../Element/Loader';
import OtpInput from 'react-otp-input';

function IsEmailVerified(){
	const dispatch = useDispatch()
	const history = useHistory()
    const [loading, setLoading] = useState(true)
	const [otp , setOtp] = useState('')
	const submitFunc = async(e) => {
		e.preventDefault()
        console.log(otp)
		try {
			if(otp !== ''){
			const variables = {
				token: otp
			}

			const data = await graphQLClient.request(VerifyEmail,variables)
			if(data){
				swal({
					title: "Sucessful Request",
					text: "Your message has been sent sucessfully, we will respond as soon as possible",
					icon: "success",
					dangerMode: true,
				  })
				  setOtp('')
				  dispatch(EDIT_USER_STATUS(true))
				  return history.push('/activate-account')
			}
			}else{
				swal({
					title: "Error Message",
					text: "Invalid input details provided",
					icon: "warning",
					dangerMode: true,
				  })
			}
			
		} catch (error) {
			console.log(error);
			swal({
				title: "Error Message",
				text: "An error occured message was not sent try again",
				icon: "warning",
				dangerMode: true,
			  })
		}
	}
    const func = async() => {
		try {
			const data = await graphQLClient.request(userDetail)
			console.log(data.user)
			const {id, 
				isverified,
				username,
				 name, 
				 email, 
				 phone, 
				 city,
				 state,
				  pic,
				  title,
				  language,
				  age,
				salary,
				expected,
				description,
				country,
				postalcode,
				address,
				projects,
				expriences,
				skills,
				uploads,
				proves,
				payment_status,
				unread_chats
			} = data.user
			dispatch(LOAD_USER_DETAILS({
				id: id,
				isverified: isverified,
				name: name,
				username: username,
				email: email,
				phone: phone,
				country: country,
				image: pic,
				title: title,
				language: language,
				age: age,
				salary: salary,
				expected: expected,
				description: description,
				postalcode: postalcode,
				city: city,
				state: state,
				address: address,
				exprience: expriences,
				projects: projects,
				skills: skills,
				portfolio: uploads,
				prove: proves,
				payment_status: payment_status,
				unread_chats: unread_chats?.length,

			}))
			//Send token after email is avaliable
			const res = await graphQLClient.request(SEND_TOKEN);
			console.log(res)
            if(res){
                			swal({
				title: "Success Message",
				text: "A token has been sent to your registered email",
				icon: "success",
			  })
		// 
            }
		setLoading(false)
		// console.log(user)
	
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 

    useEffect(()=>{
        func()
        },[])
	return(
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
                    {
							loading?
							<div >
								<Loader />
								</div>
							:
	<>
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
							<Profilesidebar />
								<div className="col-xl-9 col-lg-8 m-b30">
									<div className="job-bx job-profile">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Email verification</h5>
											<Link to={"/jobs-cv-manager"} className="site-button right-arrow button-sm float-right">Back</Link>
										</div>
										
											<div className="row">
												<div className="col-lg-12 m-b10">
                                                <OtpInput
                                                containerStyle="form-group"
                                                inputStyle={{width: '3rem',height: "3rem",borderColor:'#3396d1'}}
                                                focusStyle={{borderColor:'#3396d1'}}
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    separator={<span>-</span>}
													isInputNum={true}
													shouldAutoFocus={true}
                                                />
												</div>
												{/* <div className="col-lg-6">
													<div className="form-group">
														<label>Confirm New Password</label>
														<input type="password" className="form-control" value={form.confirm} onChange={(e)=> setForm({...form, confirm: e.target.value})} />
													</div>
												</div> */}
												<div className="col-lg-12 m-b10">
													<button className="site-button" onClick={(e)=> submitFunc(e)}>Verify</button>
												</div>
											</div>
									
									</div>
								</div>
							</div>
						</div>
					</div>
                    </>						
}
				</div>
			</div>
			<Footer />
		</>
	)
}
export default IsEmailVerified;