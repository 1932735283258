import React from 'react';
import {Link} from 'react-router-dom';
var img1 = require('./../../images/city/lagos.jpg');
var img2 = require('./../../images/city/ABUJA.jpg');
var img3 = require('./../../images/city/rivers.jpg');
var img4 = require('./../../images/city/Enugu.jpg');
var img5 = require('./../../images/city/kaduna.jpg');
var img6 = require('./../../images/city/Kano.jpg');
var img7 = require('./../../images/city/ibadan.jpg');
var img8 = require('./../../images/city/WARRI.jpg');

function Featureblog(){
	return(
		<div className="section-full content-inner bg-gray">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 section-head text-center">
						<h2 className="m-b5">Featured Handimen</h2>
						<h6 className="fw4 m-b0">20+ Featured Handimen</h6>
					</div>
				</div>
				<div className="row"> 
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30" >
						<Link to={{pathname: "/handimans",search: `?utm=Lagos`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Lagos', redirect: false}}}}>
							<div className="city-bx align-items-end d-flex" style={{backgroundImage:"url(" + img1 + ")" }}>
								<div className="city-info">
									<h5>Lagos</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Abuja`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Abuja', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img2 + ")" }}>
								<div className="city-info">
									<h5>Abuja</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Rivers`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Rivers', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img3 + ")" }}>
								<div className="city-info">
									<h5>Port Harcourt</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Enugu`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Enugu', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img4 + ")" }}>
								<div className="city-info">
									<h5>Enugu</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Kaduna`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Kaduna', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img5 + ")" }}>
								<div className="city-info">
									<h5>Kaduna</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Kano`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Kano', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img6 + ")" }}>
								<div className="city-info">
									<h5>Kano</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Ogun`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Ogun', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img7 + ")" }}>
								<div className="city-info">
									<h5>Ibadan</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
					<Link to={{pathname: "/handimans",search: `?utm=Delta`,state: { previousLocation: '/home', searched: true, search: {skill: '',city: '', location:'Delta', redirect: false}}}}>
							<div className="city-bx align-items-end  d-flex" style={{backgroundImage:"url(" + img8 + ")" }}>
								<div className="city-info">
									<h5>Warri</h5>
									<span>Handimen</span>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Featureblog;