import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom';
import Header from './../Layout/userHeader';
import Footer from './../Layout/Footer';
import Profilesidebar from './../Element/Profilesidebar';
import {graphQLClient}  from '../../client';
import {userDetail, UserUpdate} from '../../queries';
import {LOAD_USER_DETAILS,EDIT_USER_NAME,EDIT_USER_TIILE,EDIT_USER_LANGUAGE,EDIT_USER_AGE,EDIT_USER_SALARY,EDIT_USER_EXPECTED,EDIT_USER_DESCRIPTION,EDIT_USER_PHONE,EDIT_USER_COUNTRY,EDIT_USER_POSTALCODE,EDIT_USER_CITY,EDIT_USER_ADDRESS,EDIT_USER_STATE } from '../../store/actions'
import swal from 'sweetalert';
import Loader from '../Element/Loader';
import 'react-phone-number-input/style.css'
import '../../css/phone-style.css'
import PhoneInput from 'react-phone-number-input'

function Jobprofile(){
	const userDetails = useSelector((state)=> state.user)
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const func = async() => {
		try {
			const data = await graphQLClient.request(userDetail)
			console.log(data.user)
			const {id, 
				isverified,
				username,
				 name, 
				 email, 
				 phone, 
				 city,
				 state,
				  pic,
				  title,
				  language,
				  age,
				salary,
				expected,
				description,
				country,
				postalcode,
				address,
				projects,
				expriences,
				skills,
				uploads,
				proves,
				payment_status,
				unread_chats
			} = data.user
			dispatch(LOAD_USER_DETAILS({
				id: id,
				isverified: isverified,
				name: name,
				username: username,
				email: email,
				phone: phone,
				country: country,
				image: pic,
				title: title,
				language: language,
				age: age,
				salary: salary,
				expected: expected,
				description: description,
				postalcode: postalcode,
				city: city,
				state: state,
				address: address,
				exprience: expriences,
				projects: projects,
				skills: skills,
				portfolio: uploads,
				prove: proves,
				payment_status: payment_status,
				unread_chats: unread_chats?.length,

			}))
			setLoading(false)
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setLoading(false)
		}
	} 
// Update User Details
const updateUser = async(e) =>{
	e.preventDefault()
console.log(userDetails)
if(userDetails.salary ==null){
	console.log("nini is empty")
	return swal({
		title: "Validation Error",
		text: "NIN is compulsory in updating your profile, Please key in your NIN",
		icon: "error",
	  })
}
try {
	const variables = {
		name: userDetails.name,
		phone: userDetails.phone,
		country: userDetails.country,
		image: userDetails.pic,
		title: userDetails.title,
		language: userDetails.language,
		age: userDetails.age,
		salary: userDetails.salary,
		expected: userDetails.expected,
		description: userDetails.description,
		postalcode: userDetails.postalcode,
		city: userDetails.city,
		state: userDetails.state,
		address: userDetails.address,

	}
	const data = await graphQLClient.request(UserUpdate,variables)
	console.log(data)
	if(data){
		swal({
			title: "Success Message",
			text: "Profile update has been completed sucessfully",
			icon: "success",
		  })
	}

	
} catch (error) {
	console.log(error?.response?.errors[0]?.message)
}

}

	useEffect(()=>{
	func()
	},[])
	return(
		<>	
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
				{
							loading?
							<div >
								<Loader />
								</div>
							:
	<>
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar /> 
								<div className="col-xl-9 col-lg-8 m-b30">
									<div className="job-bx job-profile">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Basic Information</h5>
											<Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link>
										</div>
										<form>
											<div className="row m-b30">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Your Name:</label>
														<input type="text" className="form-control" placeholder="" defaultValue={userDetails.name} onChange={(e)=> dispatch(EDIT_USER_NAME(e.target.value)) } /> 
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Professional title:</label>
														<input type="text" className="form-control" placeholder="" defaultValue={userDetails.title} onChange={(e)=> dispatch(EDIT_USER_TIILE(e.target.value)) } />
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Languages:</label>
														<select className="form-control" defaultValue={userDetails.language} onChange={(e)=> dispatch(EDIT_USER_LANGUAGE(e.target.value)) }>
															<option>
																English
															</option>
														</select>
														{/* <input type="text" className="form-control" placeholder="English" defaultValue={userDetails.language} onChange={(e)=> dispatch(EDIT_USER_LANGUAGE(e.target.value)) }/> */}
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Age:</label>
														<input type="text" className="form-control" placeholder="" defaultValue={userDetails.age} onChange={(e)=> dispatch(EDIT_USER_AGE(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>NIN</label>
														<input type="text" className="form-control" placeholder="NIN" defaultValue={userDetails.salary} onChange={(e)=> dispatch(EDIT_USER_SALARY(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Sex:( {userDetails.expected})</label>
														<select className="form-control" defaultValue={userDetails.expected} onChange={(e)=> dispatch(EDIT_USER_EXPECTED(e.target.value)) }>
															<option value="Male">MALE</option>
															<option value="FEMALE">FEMALE</option>
														</select>
														{/* <input type="text" className="form-control" placeholder="Sex" defaultValue={userDetails.expected} onChange={(e)=> dispatch(EDIT_USER_EXPECTED(e.target.value)) }/> */}
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<label>Description:</label>
														<textarea  className="form-control" defaultValue={userDetails.description} onChange={(e)=> dispatch(EDIT_USER_DESCRIPTION(e.target.value)) }>
														
														</textarea>
													</div>
												</div>
											</div>
											<div className="job-bx-title clearfix">
												<h5 className="font-weight-700 pull-left text-uppercase">Contact Information</h5>
											</div>
											<div className="row">
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Phone:</label>
														<PhoneInput
														 className="phone-input" 
														
														placeholder="Enter phone number"
														value={userDetails.phone}
														onChange={(e)=> dispatch(EDIT_USER_PHONE(e))}/>
														{/* <input type="text" className="form-control" placeholder="+1 123 456 7890" defaultValue={userDetails.phone} onChange={(e)=> dispatch(EDIT_USER_PHONE(e.target.value)) }/> */}
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Email Address:</label>
														<input type="text" className="form-control" placeholder="info@example.com" defaultValue={userDetails.email} disabled />
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Country:</label>
														<input type="text" className="form-control" placeholder="Country Name" defaultValue={userDetails.country} onChange={(e)=> dispatch(EDIT_USER_COUNTRY(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-3 col-md-3">
													<div className="form-group">
														<label>Postcode:</label>
														<input type="text" className="form-control" placeholder="112233" defaultValue={userDetails.postalcode} onChange={(e)=> dispatch(EDIT_USER_POSTALCODE(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-3 col-md-3">
													<div className="form-group">
														<label>State:</label>
														<input type="text" className="form-control" placeholder="state" defaultValue={userDetails.state} onChange={(e)=> dispatch(EDIT_USER_STATE(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-3 col-md-3">
													<div className="form-group">
														<label>City:</label>
														<input type="text" className="form-control" placeholder="London" defaultValue={userDetails.city} onChange={(e)=> dispatch(EDIT_USER_CITY(e.target.value)) }/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6">
													<div className="form-group">
														<label>Full Address:</label>
														<input type="text" className="form-control" placeholder="New york City" defaultValue={userDetails.address} onChange={(e)=> dispatch(EDIT_USER_ADDRESS(e.target.value)) }/>
													</div>
												</div>
											</div>
											<button className="site-button m-b30" onClick={(e)=> updateUser(e)}>Update Profile</button>
										</form>
									</div>    
								</div>
							</div>
						</div>
					</div>
					</>						
}
				</div>
			</div>
			<Footer />	
		</>
	)
} 
export default Jobprofile;