import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Homepage from './Pages/Homepage1';
import Login from './Pages/Loginpage2'

import Jobprofile from './Pages/Jobprofile';
import Jobmyresume from './Pages/Jobmyresume';
import Changepasswordpage from './Pages/Changepasswordpage';



import Aboutus from './Pages/Aboutus';
import Jobdetail from './Pages/Jobdetail';
import Browsejobfilterlist from './Pages/Browsejobfilterlist';


import Portfoliogrid2 from './Pages/Portfoliogrid2';

import Register2 from './Pages/Register2';
import Error404 from './Pages/Error404';

import Contact from './Pages/Contact';


import Blogdetailgrid from './Pages/Blogdetailgrid';
import ScrollToTop from './Element/ScrollToTop';
import ProtectedUserRoute from '../ProtectedUserRoute';
import Register1 from './Pages/Register1';
import IsEmailVerified from './Pages/emailVerification';
import Jobpayment from './Pages/payment';
import HandimanInAppChat from './Pages/handimanInAppChat';
import chats from './Pages/Chats';
import PasswordRecovery from './Pages/passwordRecovery'
import ResetPassword from './Pages/passwordReset';

const Markup = () => {

	return(
		<>
			<div className="page-wraper">
				<Switch>
					<Route path='/' exact component={Homepage} />
					<Route path='/home' exact component={Homepage} />
					<Route path='/about-us' exact component={Aboutus} />
				
					<Route path='/handimans' exact component={Browsejobfilterlist} />
					<Route path='/newsfeed' exact component={Blogdetailgrid} />
					<Route path='/login' exact component={Login} />
					<Route path='/employer/signup' exact component={Register1} />
					<Route path='/register' exact component={Register2} />
					<Route path='/pwd-recovery' exact component={PasswordRecovery} />
					<Route path='/pwd-reset/:token' exact component={ResetPassword} />
					<Route path='/contact' exact component={Contact} />
					<Route path='/gallary' exact component={Portfoliogrid2} /> 
					
					{/* <Route path='*' exact component={Error404} /> */}
					<ProtectedUserRoute path='/chats' exact component={chats} /> 
					<ProtectedUserRoute path='/in-app-chat' exact component={HandimanInAppChat} /> 
					<ProtectedUserRoute path='/job-detail' exact component={Jobdetail} />
					<ProtectedUserRoute path='/activate-account' exact component={Jobpayment} />
					<ProtectedUserRoute path='/profile' exact component={Jobprofile} />
					<ProtectedUserRoute path='/change-password' exact component={Changepasswordpage} />
					<ProtectedUserRoute path='/my-resume' exact component={Jobmyresume} />
					<ProtectedUserRoute path='/email-verification' exact component={IsEmailVerified} />
				</Switch>
			</div>
			<ScrollToTop />
		</>	
	)
	
	
}

export default Markup;