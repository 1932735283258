import React, { useState} from 'react'
import { Link,useHistory } from 'react-router-dom'
import swal from 'sweetalert';
import {Spinner} from 'react-bootstrap'
// image
//import logo from "../../images/logo-full-white.png";
import loginbg from "./../../images/background/pexels-cottonbro-5089178.jpg";
import logo2 from './../../images/custom-logo3.png';

import {graphQLClient}  from '../../client';
import {login} from '../../queries'
import {useCustomer} from '../../authenicationProvider'

// Socket join
import { socketClient } from '../../client';
function Login (props) {

	const {setCustomer, setUsername} = useCustomer()
  const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
	const [processing, setProcessing] = useState(false);
	let history = useHistory();

    async function onLogin(e) {
        e.preventDefault();
		setProcessing(true)
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			setProcessing(false)
			return ;
		}
	
		try {
			const variables = {
				login: email,
				password: password, 
			  }
	
			const data = await graphQLClient.request(login, variables)
			setCustomer(data.signIn.role)
			setUsername(data.signIn.username)
			setProcessing(false)
	   console.log(data.signIn.username)

			if(data){
				 // Join user to Socket
				socketClient.emit('joined', data.signIn.username);
				return  history.push("/profile" )
			}
			
		} catch (error) {
			console.log(error?.response?.errors[0]?.message)
			setProcessing(false)
			swal({
				title: "Error Message",
				text: error?.response?.errors[0]?.message,
				icon: "warning",
				dangerMode: true,
			  })
		}
    }

  return (
	
		
		<div className="page-wraper">
			<div className="page-content bg-white login-style2" style={{backgroundImage:"url("+ loginbg +")", backgroundSize:"cover"}}>
				<div className="section-full">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 d-flex">
								<div className="text-white max-w400 align-self-center">
									<div className="logo">
										<Link to={"/"}><img src={logo2} alt="" /></Link>
									</div>
									<h2 className="m-b10">Login To You Now</h2>
									<p className="m-b30">Expose your skills to the world around you!</p>
									<ul className="list-inline m-a0">
										<li><Link to={''} className="m-r10 text-white "><i className="fa fa-facebook"></i></Link></li>
										<li><Link to={''} className="m-r10 text-white "><i className="fa fa-google-plus"></i></Link></li>
										<li><Link to={''} className="m-r10 text-white "><i className="fa fa-linkedin"></i></Link></li>
										<li><Link to={''} className="m-r10 text-white "><i className="fa fa-instagram"></i></Link></li>
										<li><Link to={''} className="m-r10 text-white"><i className="fa fa-twitter"></i></Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="login-2 submit-resume p-a30 seth">
									<div className="nav">
										<form onSubmit={onLogin} className="col-12 p-a0 ">
											<p className="font-weight-600">If you have an account with us, please log in.</p>
											{props.errorMessage && (
												<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
													{props.errorMessage}
												</div>
											)}
											{props.successMessage && (
												<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
													{props.successMessage}
												</div>
											)}
											<div className="form-group ">
												<label>E-Mail Address*</label>
												<div className="input-group">
													<input type="email" className="form-control" 
														placeholder="Type Your Email Address"  
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
													{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
												</div>
											</div>
											<div className="form-group">
												<label>Password *</label>
												<div className="input-group">
													<input
														type="password"
														className="form-control"
														value={password}
														placeholder="Type Your Password"
														onChange={(e) =>
															setPassword(e.target.value)
														}
													/>
													{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
												</div>
											</div>
											<div className="text-center">
												<button className="site-button float-left" style={{width: '80px', height:'40px', textAlign: 'center', padding: 0}} disabled={processing}>
													{
														processing?
											<Spinner animation="border" />
											:
											'login' 
													}
												
													
													</button>
												<Link  to="register" className="site-button-link forget-pass m-t15 float-right"><i className="fa fa-unlock-alt"></i> Sign up</Link> 
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<footer className="login-footer">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<span className="float-left">© Copyright by <i className="fa fa-heart m-lr5 text-red heart"></i>
								<Link to={"#"}>Ideal SmartTech Limited </Link> </span>
								<span className="float-right">
									All rights reserved.
								</span>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};


export default Login;
