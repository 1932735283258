export const EDITE_ROLE = (data) => {
	return{
		type: "EDITE_ROLE",
		payload: data
	}
}
export const LOAD_USER_DETAILS = (data) => {
	return{
		type: "LOAD_USER_DETAILS",
		payload: data
	}
}
export const UPLOAD_IMG = (data) => {
	return{
		type: "UPLOAD_IMG",
		payload: data
	}
}
export const EDIT_USER_NAME = (data) => {
	return{
		type: "EDIT_USER_NAME",
		payload: data
	}
}
export const EDIT_USER_TIILE = (data) => {
	return{
		type: "EDIT_USER_TIILE",
		payload: data
	}
}
export const EDIT_USER_LANGUAGE = (data) => {
	return{
		type: "EDIT_USER_LANGUAGE",
		payload: data
	}
}
export const EDIT_USER_AGE = (data) => {
	return{
		type: "EDIT_USER_AGE",
		payload: data
	}
}

export const EDIT_USER_SALARY = (data) => {
	return{
		type: "EDIT_USER_SALARY",
		payload: data
	}
}
export const EDIT_USER_EXPECTED = (data) => {
	return{
		type: "EDIT_USER_EXPECTED",
		payload: data
	}
}
export const EDIT_USER_DESCRIPTION = (data) => {
	return{
		type: "EDIT_USER_DESCRIPTION",
		payload: data
	}
}
export const EDIT_USER_PHONE = (data) => {
	return{
		type: "EDIT_USER_PHONE",
		payload: data
	}
}



export const EDIT_USER_COUNTRY = (data) => {
	return{
		type: "EDIT_USER_COUNTRY",
		payload: data
	}
}
export const EDIT_USER_STATE = (data) => {
	return{
		type: "EDIT_USER_STATE",
		payload: data
	}
}
export const EDIT_USER_POSTALCODE = (data) => {
	return{
		type: "EDIT_USER_POSTALCODE",
		payload: data
	}
}
export const EDIT_USER_CITY = (data) => {
	return{
		type: "EDIT_USER_CITY",
		payload: data
	}
}
export const EDIT_USER_ADDRESS = (data) => {
	return{
		type: "EDIT_USER_ADDRESS",
		payload: data
	}
}
export const ADD_USER_SkILL = (data) => {
	return{
		type: "ADD_USER_SkILL",
		payload: data
	}
}

export const ADD_USER_EXPRIENCE = (data) => {
	return{
		type: "ADD_USER_EXPRIENCE",
		payload: data
	}
}

export const ADD_USER_PORTFOLIO = (data) => {
	return{
		type: "ADD_USER_PORTFOLIO",
		payload: data
	}
}
export const ADD_USER_PROVE = (data) => {
	return{
		type: "ADD_USER_PROVE",
		payload: data
	}
}
export const ADD_USER_PROJECT = (data) => {
	return{
		type: "ADD_USER_PROJECT",
		payload: data
	}
}
export const LOAD_USER_RESUME = (data) => {
	return{
		type: "LOAD_USER_RESUME",
		payload: data
	}
}
export const LOAD_USERS = (data) => {
	return{
		type: "LOAD_USERS",
		payload: data
	}
}
export const FILTER_USERS = (data) => {
	return{
		type: "FILTER_USERS",
		payload: data
	}
}
export const LOAD_SINGLE_USER = (data) => {
	return{
		type: "LOAD_SINGLE_USER",
		payload: data
	}
}
export const EDIT_USER_STATUS = (data) => {
	return{
		type: "EDIT_USER_STATUS",
		payload: data
	}
}
export const USER_PAYMENT_STATUS = (data) => {
	return{
		type: "USER_PAYMENT_STATUS",
		payload: data
	}
}
export const SEND_MSG = (data) => {
	return{
		type: "SEND_MSG",
		payload: data
	}
}
export const UPDATE_MSG_URL = (data) => {
	return{
		type: "UPDATE_MSG_URL",
		payload: data
	}
}

export const LOAD_THREAD = (data) => {
	return{
		type: "LOAD_THREAD",
		payload: data
	}
}
export const LOAD_MSG = (data) => {
	return{
		type: "LOAD_MSG",
		payload: data
	}
}
export const OTHER_MSG = (data) => {
	return{
		type: "OTHER_MSG",
		payload: data
	}
}
export const LOAD_STATES = (data) => {
	return{
		type: "LOAD_STATES",
		payload: data
	}
}
export const LOAD_CITIES = (data) => {
	return{
		type: "LOAD_CITIES",
		payload: data
	}
}
export const USER_LOAD_STATES = (data) => {
	return{
		type: "USER_LOAD_STATES",
		payload: data
	}
}
export const USER_LOAD_CITIES = (data) => {
	return{
		type: "USER_LOAD_CITIES",
		payload: data
	}
}
export const SET_RECIPIENT = (data) => {
	return{
		type: "SET_RECIPIENT",
		payload: data
	}
}
export const MSG_COUNT = () => {
	return{
		type: "MSG_COUNT"
	}
}
export const MSG_COUNT_SUB = (data) => {
	return{
		type: "MSG_COUNT_SUB",
		payload: data
	}
}
export const READ_ALL = (data) => {
	return{
		type: "READ_ALL",
		payload: data
	}
}
export const READ_ONE = (data) => {
	return{
		type: "READ_ONE",
		payload: data
	}
}