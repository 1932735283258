import React, {useState, useEffect} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {graphQLClient}  from '../../client';
import {resetUserPassword,checkToken} from '../../queries';
import { toast } from 'react-toastify';
import {Spinner} from 'react-bootstrap';


import bnr from "./../../images/background/pexels-field-engineer-442151.jpg";


function ResetPassword() {
	const { token } = useParams();
    let errorsObj = { confirm: '', password: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false)
	console.log(token)
	const [processing, setProcessing] = useState(false);
	let history = useHistory();
// Click Reset button
    async function onReset(e) {
        e.preventDefault();
		setProcessing(true)
        let error = false;
        const errorObj = { ...errorsObj };
        if (confirm === '') {
            errorObj.confirm = 'Confirm is Required';
            error = true;
        }else if(confirm !== password){
            errorObj.confirm = 'Password and Confirm Password must be the same.';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
	
        setErrors(errorObj);
		if (error) {
			setProcessing(false)
			return ;
		}
       if(password.length < 7){
			setProcessing(false)
		   toast.warning(`Password length can not be less than 7 characters`)
		   return ;
	   }
		try {
				const variables = {
				 token: token, 
				 password: password
			  }
	
			 await graphQLClient.request(resetUserPassword, variables).then((data)=>{
				console.log(data)
				setProcessing(false)
				if(data){
					history.push("/login")
				}
			}).catch((error)=> {
				console.log(error)
				setProcessing(false)
				toast.success(`An error has occured please try again`)

			}
			)
		
		} catch (error) {
			console.log(error)
			setProcessing(false)

		}

		
    }
	// Check token func
	const func = async () =>{
		const variables = {
			token: token, 
		 }

		await graphQLClient.request(checkToken, variables).then((data)=>{
		   console.log(data)
			setLoading(false)
	   }).catch((error)=> {
		setLoading(false)
		   setError(true)
		console.log(error)
	   }
	   )
	}
	useEffect(()=>{
		func()
	},[])

	return(
		<div className="page-wraper">
			<div className="browse-job login-style3">
				
				<div className="bg-img-fix" style={{backgroundImage:`url(${bnr})`, height: "100vh" }}>
					<div className="row">
						<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
							<div className="login-form style-2">
								<div className="logo-header text-center p-tb30">
									<Link to={"./"}><img src={require("./../../images/custom-logo3.png")} alt="logo"  /></Link>
								</div>
								<div className="clearfix"></div>
								<div className="tab-content nav p-b30 tab">
									<div id="login" className="tab-pane active ">
									
										<form className=" dez-form p-b30" onSubmit={onReset}>
										<h3 className="form-title m-t0">Password Reset</h3>
										<div className="dez-separator-outer m-b5">
											<div className="dez-separator bg-primary style-liner"></div>
										</div>
										{
											loading || error == true?
								<div className='col-md-12' style={{display: 'flex', flexDirection:"column", justifyContent:'center'}}>
									{
										error?
										<div className="alert alert-danger" role="alert">
										Password recovery link has expired, please get another
										</div>
										:
										<div className="alert alert-warning" role="alert">
											Please Wait, we validating your authentication details
											</div>	
									}

											<img  src="https://i.imgur.com/247tYSw.png" className='img-responsive' style={{width: "40%", height:"40%",position: "relative",left: "50%" ,transform:'translateX(-50%)'}}  />
										{
											loading?
											<div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
											<Spinner animation="border" variant="primary" />
											</div>
											:
											null
										}
											{
												error?
												<div style={{display:'flex', justifyContent:'center',marginTop: "10px",position: "relative",left: "50%" ,transform:'translateX(-50%)',padding: "10px 4px", width:"40%", borderRadius:"10px", background:"rgb(46, 85, 250)", color:"#fff"}}>
												<Link to={"/pwd-recovery"} style={{color:"#fff",textAlign:'center'}}>Back</Link>
											</div>
											:
											null
											}
											
										</div>
										:
											<>
										<p>Enter a new password of your choice. </p>
										<div className="form-group">
											<input type='password' value={password} className="form-control"  placeholder="Password"
												onChange={(e) =>
													setPassword(e.target.value)
												}												  
											/>
											<div className="text-danger">{errors.password && <div>{errors.password}</div>}</div>
										</div>
										<div className="form-group">
											<input type='password' value={confirm} className="form-control"  placeholder="Confirm Password"
												onChange={(e) =>
													setConfirm(e.target.value)
												}												  
											/>
											<div className="text-danger">{errors.confirm && <div>{errors.confirm}</div>}</div>
										</div>
										<div className="text-center bottom">
										<button type="submit" className="site-button button-md btn-block text-white"  disabled={processing}>
												{
													processing?
										<Spinner animation="border" />
										:
										'Reset' 
												}
											
												
												</button>
										</div>
										</>
}
									</form>
									
										
										
									
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	)
}

export default ResetPassword;