import React,{useEffect,useState} from 'react';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import ReactStarsRating from 'react-awesome-stars-rating';
import {FormateDateTime} from 'react-date-time-formatter';
import {useSelector} from 'react-redux';

// Handimans Card 
function Items({ currentItems }) {
	const rater = (arr) =>{
		if(arr?.length == 0){
			return 0
		}
		let total =	0;
		for(let i =0; i< arr.length; i++){
			total += parseInt(arr[i].rating)
		}
		let len = arr.length;
		let average = total/len;
		let random = Math.floor(average);
		console.log(random)
		return random
		
		}
	return (
	  <>
		{currentItems &&
		 	<ul className="post-job-bx">
			 {
			 currentItems.map((item,index)=>(
				 <li key={index}>
					 <div className="post-bx">
						 <div className="d-flex m-b30">
							 <div className="job-post-company">
								 <Link to= {''}><span>
									 <img alt="" src={item.pic} />
								 </span></Link>
							 </div>
							 <div className="job-post-info">
								 <h4><Link to={{
									 pathname:"/job-detail",
									//  search:'?sort=name',
									//   hash:'#the-hash',
									 state: {previousLocation: '/home', userid: item.id}
								 }}>{item.name}</Link></h4>
								 <div className="salary-bx" style={{color: 'grey'}}>
								 <span>{item.title}</span>
								 </div>
								 <ul>
									 <li><i className="fa fa-map-marker"></i> {item.city? item.city : "Anonymous"}</li>
									 {/* <li><i className="fa fa-usd"></i> Full Time</li> */}
									 {/* <li><i className="fa fa-clock-o"></i> Joined <FormateDateTime  >{item.createdAt}</FormateDateTime >
									  </li> */}
								 </ul>
							 </div>
						 </div>
						 <div className="d-flex" style={{justifyContent:'space-between'}}>
							 <div className="job-time col-md-6">
								 {
								 item.skills.map((item,index)=>(
									 <Link to ={"#"} key={index}><span style={{marginRight: 5}}>{item.skills}</span></Link>
								 ))	
								 }

							 </div>
							 <div className="salary-bx">

							 <ReactStarsRating  value={rater(item.reviews)} disable />
							 </div>
						 </div>
						 <label className="like-btn">
							   <input type="checkbox" />
							   <span className="checkmark"></span>
						 </label>
					 </div>
				 </li>
			 ))}
		 </ul>
		  
		  }

		  
	  </>
	);
  }
  function Handimans({ itemsPerPage }) {
    const users = useSelector((state)=> state.web.users)
	// We start with an empty list of items.
	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);


	useEffect(() => {
	  // Fetch items from another resources.
	  const endOffset = itemOffset + itemsPerPage;
	  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	  setCurrentItems(users.slice(itemOffset, endOffset));
	  setPageCount(Math.ceil(users.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, users]);
  
	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
	  const newOffset = (event.selected * itemsPerPage) % users.length;
	  console.log(
		`User requested page number ${event.selected}, which is offset ${newOffset}`
	  );
	  setItemOffset(newOffset);
	};
  
	return (
	  <>

		  <Items currentItems={currentItems} />
		  <ReactPaginate
		  nextLabel="next >"
		  onPageChange={handlePageClick}
		  pageRangeDisplayed={3}
		  marginPagesDisplayed={2}
		  pageCount={pageCount}
		  previousLabel="< previous"
		  pageClassName="page-item"
		  pageLinkClassName="page-link"
		  previousClassName="page-item"
		  previousLinkClassName="page-link"
		  nextClassName="page-item"
		  nextLinkClassName="page-link"
		  breakLabel="..."
		  breakClassName="page-item"
		  breakLinkClassName="page-link"
		  containerClassName="pagination pagination-bx float-right m-t30"
		  activeClassName="active"
		  renderOnZeroPageCount={null}
		  />


	  </>
	);
  }

  export default Handimans