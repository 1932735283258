import React from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import Latestblogowl from './../Element/Owlblog2';

var bnr1 = require('./../../images/background/pexels-cottonbro-5089178.jpg');
var bnr2 = require('./../../images/background/pexels-field-engineer-442151.jpg');

function Aboutus(){
	return(
		<div className="page-wraper">		
			<Header />	
			<div className="page-content bg-white">
				<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + bnr1 + ")"}}>
					<div className="container">
						<div className="dez-bnr-inr-entry">
							<h1 className="text-white">About Us</h1>
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={"#"}>Home</Link></li>
									<li>About Us</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>
				<div className="content-block">
					<div className="section-full content-inner overlay-white-middle">
						<div className="container">
							<div className="row align-items-center m-b50">
								<div className="col-md-12 col-lg-6 m-b20">
									<h2 className="m-b5">About Us</h2>
									<h3 className="fw4">We create unique experiences</h3>
									<p className="m-b15">
										Service providers have existed as guilds. A recruiter walks into these guilds housing several workers offering the same or similar Service.
									</p>
									<p className="m-b15">
										Welcome to that modern day guild, Handiman offers a one stop hub for accessing, veiwing and recruiting the best services for the customers needs. we bring the customers directly in contact with their service providers thus fulfilling the true pupose of digital technology: the Harmony of man and machine. 
									</p>
									<h3 className="fw4">Mission</h3>
									<p className="m-b15">
										To link handiman to those who need their services and provide profitable value Exchange between customers and service providers.
									</p>
									<h3 className="fw4">Vission</h3>
									<p className="m-b15">
										To create a wholesome transition of society and the supporting industries into the digital future.
									</p>
									{/* <Link to={"#"} className="site-button">Read More</Link> */}
								</div>
								<div className="col-md-12 col-lg-6">
									<img src={require('./../../images/our-work/ABOUT.jpg')} alt=""/>
								</div>
							</div>
							{/* <div className="row">
								<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
									<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
										<div className="icon-md text-primary m-b20"> <Link to={"#"} className="icon-cell text-primary"><i className="ti-desktop"></i></Link> </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">Elegant / Unique design</h5>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
									<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
										<div className="icon-md text-primary m-b20"> <Link to={"#"} className="icon-cell text-primary"><i className="ti-image"></i></Link> </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">Make it Simple</h5>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
									<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
										<div className="icon-md text-primary m-b20"> <Link to={"#"} className="icon-cell text-primary"><i className="ti-cup"></i></Link> </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">Different Layout Type</h5>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.</p>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>						
					<div className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix" style={{backgroundImage:"url(" + bnr2 + ")"}}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<h2 className="m-b10">Expose your skills to the world around you!</h2>
									<p className="m-b0">The world should know what you can do.</p>
									<Link to={"/register"} className="site-button m-t20 outline outline-2 radius-xl">Create an Account</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="section-full content-inner-2 overlay-white-middle">
						<div className="container">
							<div className="section-head text-black text-center">
								<h2 className="text-uppercase m-b0">Our Latest Blog</h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
							</div>
							<Latestblogowl />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>	
	)
	
}
export default Aboutus;