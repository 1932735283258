import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom'
import {graphQLClient}  from '../../client';
import {LOGOUT} from '../../queries'
import {useMutation, gql} from "@apollo/client"
import { UPLOAD_IMG } from '../../store/actions'
import swal from 'sweetalert';
import {useCustomer} from '../../authenicationProvider'
const Upload = gql`
 mutation profileUpload($file: Upload! ) {
	profileUpload(file: $file) {
   url
  }
}
`;
function Profilesidebar(props){
	const {customer, setCustomer} = useCustomer()
	const dispatch = useDispatch()
	const userDetails = useSelector((state)=> state.user)
	let history = useHistory();
	var signOut = async (e) =>{
		e.preventDefault()
		try {
			const data = await graphQLClient.request(LOGOUT)
			console.log(data)
			setCustomer(null)
			if(data){
				return  history.push("./" )
			}
		} catch (error) {
			console.log(error.response.errors[0].message)
		}
	}
	const [uploadFile,{ loading, error }] = useMutation(Upload,{
		onCompleted: (data)=>  {
			console.log(data.profileUpload.url)
			dispatch(UPLOAD_IMG(data.profileUpload.url))
		},
		onError: ()=> 	swal({
			title: "Error Message",
			text: "An error has occured during the upload, please try again with a smaller image",
			icon: "error",
		  })
	})


		var uploadImageToStorage = async ({
			target: {
			  validity,
			  files: [file]
			}
		  }) => {
	
			console.log(file)
			validity.valid && uploadFile({ variables: { file } })
		  }
if(error){
	console.log(error)
}
	  if (loading) return <div>Loading...</div>;
	  const arr = {
		  payment_status: true,
		  isverified: false
	  }
	return(
		<div className="col-xl-3 col-lg-4 m-b30">
			<div className="sticky-top">
				<div className="candidate-info">
{
	props?.hide?
null
	:

					<div className="candidate-detail text-center">
						<div className="canditate-des">
							<Link to={''}>
								<img alt="" src={userDetails.image} />
							</Link>
							<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
								<input type="file" className="update-flie" onChange={(e)=> uploadImageToStorage(e)} />
								<i className="fa fa-camera"></i>
							</div>
						</div>
						<div className="candidate-title">
							<div className="">
								<h4 className="m-b5"><Link to={"#"}>{userDetails.name}</Link></h4>
								<p  className="m-b0"><Link to={"#"}>{userDetails.title}</Link></p>
							</div>
							<div>
								
								{
								userDetails.payment_status  && userDetails.isverified? 
							null
								:
									!userDetails.payment_status && !userDetails.isverified?
									<Link to={'/email-verification'}>Verify Account</Link>
									:
									userDetails.isverified && !userDetails.payment_status?
									<Link to={'/activate-account'}>Activate Account</Link>
											:
											<Link to={'/email-verification'}>Verify Account</Link>
								}
								{
									// console.log(userDetails)

								}
							
								</div>
						</div>
					</div>
}
					<ul>
						<li><Link to={"/profile"}
						//  className="active"
						 >
							<i className="fa fa-user-o" aria-hidden="true"></i> 
							<span>Profile</span></Link></li>
						{
							customer !=="Employer"?
							<li><Link to={"/my-resume"}>
							<i className="fa fa-file-text-o" aria-hidden="true"></i> 
							<span>My Resume</span></Link></li>
							:
							null
						}


						{/* <li><Link to={"/jobs-saved-jobs"}>
							<i className="fa fa-heart-o" aria-hidden="true"></i> 
							<span>Saved Jobs</span></Link></li> */}
						{/* <li><Link to={"/jobs-applied-job"}>
							<i className="fa fa-briefcase" aria-hidden="true"></i> 
							<span>Applied Jobs</span></Link></li> */}
						{/* <li><Link to={"/jobs-alerts"}>
							<i className="fa fa-bell-o" aria-hidden="true"></i> 
							<span>Job Alerts</span></Link></li> */}
							{/* <li><Link to={"/jobs-cv-manager"}>
							<i className="fa fa-id-card-o" aria-hidden="true"></i> 
							<span>CV Manager</span></Link></li> */}
						<li><Link to={"/change-password"}>
							<i className="fa fa-key" aria-hidden="true"></i> 
							<span>Change Password</span></Link></li>
						<li ><a href='/profile'  onClick={(e)=> signOut(e)} >
							<i className="fa fa-sign-out" aria-hidden="true"></i> 
							<span>Log Out</span></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
export default Profilesidebar;