const initState = {
	states: [],
	cities: [],
	users:[],
	reserveUsers:[],
	id:'',
	name: '',
	username: '',
	lastName:'',
	email:'',
	phone:'',
	image:'',
	role: '',
	title:'',
    language:'',
    age:'',
    salary:'',
    expected:'',
    description:'',
    country:'',
    postalcode:'',
    city:'',
    address:'',
	joined:'',
	online: false,
	exprience:[],
	projects:[],
	skills: [],
	portfolio: [],
}


const WebReducer = (state=initState,action) =>{

switch(action.type){
	case "LOAD_USERS":
		return {
				...state,
				users: action.payload,
				reserveUsers: action.payload
			}
			case "LOAD_SINGLE_USER":
				return {
					...state,
						id: action.payload.id,
						name: action.payload.name,
						username:action.payload.username,
						email: action.payload.email,
						phone: action.payload.phone,
						image: action.payload.image,
						title: action.payload.title,
						language: action.payload.language,
						age: action.payload.age,
						salary: action.payload.salary,
						expected: action.payload.expected,
						description: action.payload.description,
						country: action.payload.country,
						postalcode: action.payload.postalcode,
						city: action.payload.city,
						address: action.payload.address,
						exprience: action.payload.exprience,
						projects: action.payload.projects,
						skills: action.payload.skills,
						portfolio: action.payload.portfolio,
						joined: action.payload.joined,
						online: action.payload.online,
				}
			case "FILTER_USERS":
				console.log(action.payload)
				console.log(state.users,)
				const newState = state.reserveUsers.filter((item) =>  {
					if(item.title !== null && action.payload.location == '' && action.payload.city == '' && action.payload.skill !== ''){
						console.log('title only')
						return item.title.toLowerCase().includes(action.payload.skill.toLowerCase())
					}else if(item.state !== null && action.payload.skill == '' && action.payload.city == '' && action.payload.location !== ''){
						console.log('state only')
						return item.state.toLowerCase().includes(action.payload.location.toLowerCase())
					}else if(item.city !== null && action.payload.skill == '' && action.payload.location !== '' && action.payload.city !== ''){
						console.log('city only')
						return item.city.toLowerCase().includes(action.payload.city.toLowerCase())
					}else if(item.title !== null && action.payload.location !== '' && action.payload.city == '' && action.payload.skill !== '' && item.state !== null){
						console.log('title & state')
						return item.title.toLowerCase().includes(action.payload.skill.toLowerCase()) && item.state.toLowerCase().includes(action.payload.location.toLowerCase())
					}else if(item.title !== null && action.payload.location == '' && action.payload.city !== '' && action.payload.skill !== '' && item.city !== null){
						console.log('title & city')
						return item.title.toLowerCase().includes(action.payload.skill.toLowerCase()) && item.state.toLowerCase().includes(action.payload.location.toLowerCase())
					}else if(action.payload.location !== '' && action.payload.city !== '' && action.payload.skill == '' && item.city !== null && item.state !== null){
						console.log('state & city')
						return item.state.toLowerCase().includes(action.payload.location.toLowerCase()) && item.city.toLowerCase().includes(action.payload.city.toLowerCase())
					}else if(item.title !== null && item.state !== null && item.city !== null && action.payload.skill !== '' && action.payload.location !== '' && action.payload.city !== ''){
						console.log('title & state & city')
						return item.title.toLowerCase().includes(action.payload.skill.toLowerCase()) && item.city.toLowerCase().includes(action.payload.city.toLowerCase()) && item.state.toLowerCase().includes(action.payload.location.toLowerCase())
					}
				}
				
				// item.title? item.title.toLowerCase().includes(action.payload.skill.toLowerCase()) : false
				// 	||
					// item.state? item.state.toLowerCase().includes(action.payload.location.toLowerCase()) : false
					// ||
					// item.city? item.city.toLowerCase().includes(action.payload.city.toLowerCase()) : false
				
					
					 )
				// console.log(newState)
		return {
			...state,
			users: newState
		}
		case "LOAD_STATES":
			return {
					...state,
					states: action.payload
				}
				case "LOAD_CITIES":
			return {
					...state,
					cities: action.payload
				}
	
									   
	default:
		return state
}
}
export default WebReducer