import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import {CustomerWrapper} from './authenicationProvider'
import {ApolloProvider, ApolloClient, InMemoryCache} from '@apollo/client'
import {createUploadLink} from 'apollo-upload-client'

const client = new ApolloClient({
    cache: new InMemoryCache(),
    //@ts-ignore
    link: createUploadLink({
      uri: "https://psggraphql.herokuapp.com/graphql",
      // uri: "http://localhost:5000/graphql",
      credentials:'include'
    }),
  });
ReactDOM.render(
	<React.StrictMode>
        <CustomerWrapper>
            <ApolloProvider client={client}>
		<Provider store = {store}>
            <SimpleReactLightbox>
                <BrowserRouter >
                    <App />
                </BrowserRouter>    
            </SimpleReactLightbox>
        </Provider>	
        </ApolloProvider>
        </CustomerWrapper>
	</React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
